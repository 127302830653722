// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1748 1.56641L6.95605 5.78516L11.1396 9.96875C11.4912 10.2852 11.4912 10.8125 11.1396 11.1289C10.8232 11.4805 10.2959 11.4805 9.97949 11.1289L5.76074 6.94531L1.57715 11.1289C1.26074 11.4805 0.733398 11.4805 0.416992 11.1289C0.0654297 10.8125 0.0654297 10.2852 0.416992 9.93359L4.60059 5.75L0.416992 1.56641C0.0654297 1.25 0.0654297 0.722656 0.416992 0.371094C0.733398 0.0546875 1.26074 0.0546875 1.6123 0.371094L5.7959 4.58984L9.97949 0.40625C10.2959 0.0546875 10.8232 0.0546875 11.1748 0.40625C11.4912 0.722656 11.4912 1.25 11.1748 1.56641Z"
      fill="#00708D"
    />
  </svg>
);
