import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import {
  ModalProps,
  ModalRef,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { Form } from '@faxi/web-form';
import { useTranslation } from 'react-i18next';

import { useCallbackAsync } from 'hooks';
import { RewardType, REWARD_STATUS, RewardTypes, REWARD_TYPES } from 'models';
import { apiGamification } from 'modules';
import { TRANSLATION_KEYS } from './utils';
import { snackBarSuccessMessage } from 'utils';
import { InfoCard } from 'components';
import { useView } from 'providers/Views';
import RewardFooter from './components/RewardFooter';

import * as Styled from './RewardModal.styles';

const REWARDS_INITIAL_DATA = {
  [REWARD_TYPES.MESSAGE_REWARD]: [],
  [REWARD_TYPES.PARKING_REWARD]: [{ name: '', quantity: 1 }],
} as Record<RewardTypes, any>;

export type RewardModalProps = {
  className?: string;
  communityId: number;
  rewardId: string;
  showModal: 'add' | 'edit' | 'none';
  rewardType?: RewardTypes;
  onFileDelete?: (rid: string) => void;
  onSubmit?: (data: RewardType) => void;
  onDelete?: (
    name: string,
    rid: string,
    element: HTMLButtonElement
  ) => Promise<boolean | void>;
} & Omit<ModalProps, 'onTransitionEnd'>;

export type RewardModalRef = {
  close: () => void;
  onEdit: (reward: RewardType) => void;
};

const RewardModal: FC<RewardModalProps> = (props) => {
  const {
    communityId,
    rewardId,
    triggerRef,
    showModal,
    onSubmit,
    onDelete,
    onFileDelete,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { BackButton, activeView: ActiveView } = useView();

  const [rewardType, setRewardType] = useState<RewardTypes>();
  const [rewardDetails, setRewardDetails] = useState<RewardType>();
  const [shouldDeleteImage, setShouldDeleteImage] = useState<boolean>(false);

  const modalRef = useRef<ModalRef>(null);
  const deletingImage = useRef<boolean>(false);

  const isEditing = useMemo(() => showModal === 'edit', [showModal]);

  const modalTitle = isEditing
    ? t('gamification-rewards_title_edit_reward')
    : t('gamification-rewards_button_create');

  const initialData = useMemo(
    () => ({
      name: rewardDetails?.name,
      file: rewardDetails?.image,
      description: rewardDetails?.description,
      redeem: rewardDetails?.redeem,
      enable_barrier:
        (rewardDetails?.data as { raise_ramp_counter: number })
          ?.raise_ramp_counter || false,
      instances: rewardDetails?.instances?.map((i) => ({
        ...i,
        quantity: `${i.quantity}`,
      })),
    }),
    [rewardDetails]
  );

  const disableEditing = useMemo(
    () => isEditing && !rewardDetails?.modifiable,
    [isEditing, rewardDetails?.modifiable]
  );

  const finalData = useMemo(
    () => ({
      instances: REWARDS_INITIAL_DATA[rewardType as RewardTypes],
    }),
    [rewardType]
  );

  const [handleOnSubmit, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async ({ type, file, ...data }: RewardType) => {
      const formAction = (text: string, data: RewardType) => {
        onSubmit?.(data);
        snackBarSuccessMessage(text);
        modalRef.current?.close();
      };

      const isFile = file instanceof File;

      const { enable_barrier, ...rewardFormValues } = data;

      const rewardData = {
        status: REWARD_STATUS.ACTIVE,
        ...(!isEditing && {
          type: rewardType,
        }),
        ...(isFile && {
          file,
        }),
        ...rewardFormValues,
        // this prop is allowing Parking with barrier
        // 2 is hardcoded for now until we get support for input for custom raise_ramp_counter value
        ...(rewardType === 'parking-reward' && {
          data: JSON.stringify({ raise_ramp_counter: enable_barrier ? 2 : 0 }),
        }),
      } as RewardType;

      if (deletingImage.current && isEditing) {
        setShouldDeleteImage(true);
        deletingImage.current = false;
      }

      if (isEditing) {
        const {
          data: { data: updatedReward },
        } = await apiGamification.updateReward(rewardId, rewardData);
        triggerRef?.focus();

        formAction(
          t(TRANSLATION_KEYS.SUCCESSFULLY_UPDATED, {
            reward: updatedReward.name,
          }),
          updatedReward
        );
        return;
      }

      const {
        data: { data: newReward },
      } = await apiGamification.createReward(communityId, rewardData);
      triggerRef?.focus();

      formAction(
        t(TRANSLATION_KEYS.SUCCESSFULLY_CREATED, {
          reward: newReward.name,
        }),
        newReward
      );
    },
  });

  const handleDeleteReward = useCallback(
    async (rewardName: string, target: HTMLElement) => {
      const proceed = await onDelete?.(
        rewardName,
        rewardId,
        target as HTMLButtonElement
      );

      if (proceed) modalRef.current?.close();
    },
    [onDelete, rewardId]
  );

  const handleDeletingImage = useCallback(() => {
    deletingImage.current = true;
  }, []);

  const closeModal = useCallback(() => {
    modalRef.current?.close();
  }, []);

  const formWrapper = useCallback(
    ({ children }: any) => (
      <Form
        children={children}
        strictValidation={false}
        initialData={showModal === 'edit' ? initialData : finalData}
        className="kinto-modal-rewards__form"
        onSubmit={handleOnSubmit}
      />
    ),
    [initialData, finalData, showModal, handleOnSubmit]
  );

  const [getRewardDetails, loadingDetails] = useCallbackAsync({
    showSpinner: false,
    condition: !!rewardId,
    callback: async () => {
      const { data } = await apiGamification.getRewardById(rewardId);
      setRewardDetails(data.data);
      setRewardType(data.data.type);
    },
  });

  useEffectOnceWhen(() => {
    getRewardDetails();
  }, !!rewardId);

  return (
    <Styled.RewardModal
      {...rest}
      ref={modalRef}
      triggerRef={triggerRef}
      renderAsPortal
      title={modalTitle}
      titleId="reward-modal-title"
      loading={loadingDetails || loading}
      childrenWrapper={formWrapper}
      focusableElements="textarea, input, button:not([disabled])"
      className={classNames('kinto-modal-rewards', {
        'reward-type': Boolean(!rewardType),
      })}
      {...(rewardType && {
        footer: (
          <RewardFooter
            editing={isEditing}
            disableEditing={disableEditing}
            onCancel={closeModal}
            onDelete={handleDeleteReward}
          />
        ),
      })}
    >
      {disableEditing && !loadingDetails && (
        <InfoCard
          className="kinto-modal-rewards__info"
          text={t('gamification-reward_alert_cannot_edit_or_delete')}
        />
      )}

      <BackButton />

      <ActiveView
        reward={{ ...rewardDetails, type: rewardType }}
        editing={isEditing}
        disableEditing={disableEditing}
        shouldDeleteImage={shouldDeleteImage}
        onFileDelete={onFileDelete}
        onRewardType={setRewardType}
        onDeletingImage={handleDeletingImage}
      />
    </Styled.RewardModal>
  );
};

export default memo(RewardModal);
