import { getColor, Spinner } from '@faxi/web-component-library';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './SpinerWithLabel.styles';

type SpinerWithLabelProp = {
  onFinish: () => void;
};

const SpinerWithLabel = (props: SpinerWithLabelProp) => {
  const { onFinish } = props;

  const [count, setCount] = useState<number>(10);

  const onFinishRef = useRef(onFinish);

  const { t } = useTranslation();

  const spinnerLabel = useMemo(
    () =>
      Number(count) > 1
        ? t('parking_barrier_progress_raising.other', { seconds: count })
        : t('parking_barrier_progress_raising.one', { seconds: count }),
    [count, t]
  );

  useEffect(() => {
    onFinishRef.current = onFinish;
  }, [onFinish]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          onFinishRef?.current();
          clearInterval(interval);
          return prevCount;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Styled.SpinnerWithLabel>
      <Spinner size={40} color={getColor('--PRIMARY_1_1')}></Spinner>
      <div>{spinnerLabel}</div>
    </Styled.SpinnerWithLabel>
  );
};

export default SpinerWithLabel;
