import { theme } from '@faxi/web-component-library';
import { flex, phablet, pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';
import { PageLayout } from '../../components';

export const ParkingManagement = styled(PageLayout)`
  .kinto-parking-management {
    &__carparks {
      display: grid;
      grid-gap: ${theme.sizes.SIZE_20};
      grid-template-columns: repeat(auto-fit, ${pxToRem(theme.sizes.SIZE_256)});

      ${phablet(css`
        grid-template-columns: 1fr;
        grid-gap: ${theme.sizes.SIZE_8};
      `)}
    }
    &__open-barrier-label {
      display: flex;
      ${flex('row', 'center', 'center')}
      color: var(--ALERT_SUCCESS_1_1);
      line-height: 1;

      i {
        margin-right: ${theme.sizes.SIZE_12};
      }
    }

    &__empty-tab {
      margin: auto;
      margin-top: -${theme.sizes.SIZE_80};
      ${flex('column', 'center', 'center')};
      height: 100%;

      svg {
        color: var(--PRIMARY_1_1);
      }

      .empty-tab__description {
        max-width: unset;
      }
    }

    &__try-again {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_8};
      margin-top: ${theme.sizes.SIZE_32};
      color: var(--SHADE_1_2);
    }
  }

  .wcl-modal__main {
    min-height: 400px;
  }

  .wcl-modal .wcl-modal__main {
    align-items: center;

    .barrier-image {
      position: absolute;
      top: ${theme.sizes.SIZE_128};
      left: calc(((100% - 353px) / 2) + 58px);

      ${phablet(css`
        top: 124px;
      `)}

      &--animate {
        transform-origin: left;
        animation: rotate 1.5s linear forwards;
      }
    }

    h1 {
      color: var(--SECONDARY_1_1);
      font-size: ${theme.fontSizes.FONT_30};
      font-weight: 600;
      line-height: 42px;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-20deg);
    }
  }
`;
