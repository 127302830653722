import {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import {
  FieldArray,
  FormField,
  FormRef,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { Button, useModalUtilities } from '@faxi/web-component-library';

import { LeaderBoardFilterType, PointsDepot } from 'models';
import { FormActions, Icon, InputField, SwitchField } from 'components';
import SnackBar from '@faxi/web-component-library/build/components/_molecules/SnackBar/SnackBar.component';
import { POINTS_FORM_FIELDS, POINT_FIELDS } from '../../Points.page';
import { convertNumber } from './utils';
import Each from 'helpers/Each';
import LeaderBoardRangeCard from '../LeaderBoardRangeCard';
import LeaderBoardRangeModal from '../LeaderBoardRangeModal';

import numberValidation from 'validation/validators/numberValidation';
import specific from 'validation/validators/specific';

import * as Styled from './PointsForm.styles';

export type PointsType = {
  bonusPerKm: string;
  transportMode: string;
  pointsPerJourney: ReactElement;
};

const POINTS_TRANSLATIONS = {
  transportMode: 'mode_of_transport',
  bonusPerKm: 'gamification-points_label_bonus_per_km',
  pointsPerJourney: 'gamification-points_label_per_journey',
};

const TABLE_ARIA_KEYS = [
  'gamification_points-label_accessibility_points_per_kilometer_driver',
  'gamification_points-label_accessibility_bonus_points_per_kilometer_passenger',
  'gamification_points-label_accessibility_bonus_points_per_kilometer_walker',
  'gamification_points-label_accessibility_bonus_points_per_kilometer_cyclist',
];

export type PointsFormProps = {
  formRef?: FormRef;
  edit?: boolean;
  data: PointsDepot;
  pointsAreEnabled?: boolean;
};

const PointsForm: FC<PointsFormProps> = (props) => {
  const { formRef: form, edit, pointsAreEnabled, ...rest } = props;

  const { t } = useTranslation();
  const formHasError = useRef<string>('');

  const [activeFilterId, setActiveFilterId] = useState<string>();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();
  const triggerBtn = triggerRef.current as HTMLButtonElement;

  const pointsFormValues = useFormContextValues(
    'driving_points_enabled',
    'passenging_points_enabled',
    'walking_points_enabled',
    'cycling_points_enabled'
  );

  const activeFilter = useMemo(() => {
    return !activeFilterId
      ? undefined
      : form?.fields.filters.value.find(
          (filter: LeaderBoardFilterType) => filter.id === activeFilterId
        );
  }, [form, activeFilterId]);

  const pointsValidation = useMemo(
    () => [
      specific.positiveNumbersOnly(
        t('validation-field_positive_round_number_only', {
          fieldname: t('global-points').toLowerCase(),
        })
      ),
      validators.general.required(
        t('validation-fields_are_required', { fieldname: t('global-points') })
      ),
      numberValidation.minValue(
        t('validation-field_validation_min_value', {
          fieldname: t('global-points').toLowerCase(),
          number: 0,
        }),
        0
      ),
      numberValidation.maxValue(
        t('validation-field_validation_max_value', {
          fieldname: t('global-points').toLowerCase(),
          number: 100,
        }),
        100
      ),
    ],
    [t]
  );

  const validations = useMemo(
    () => ({
      enabledPoints: (value: boolean, fields: any) => {
        const allDisabled = Object.values(fields)
          .filter((field: any) => field.name.includes('enabled'))
          .every((field: any) => !field.value);

        if (allDisabled) {
          formHasError.current = t('enabled_points_warning');
          return t('enabled_points_warning');
        }

        formHasError.current = '';
        return undefined;
      },
    }),
    [t]
  );

  const calculateBonusPoints = useCallback(
    (fieldName: string) => {
      if (!form) return 0;
      const { fields } = form as FormRef;

      const field = fields[fieldName];

      const bonus = fields[POINTS_FORM_FIELDS.DISTANCE_COEFFICIENT];

      if (!field?.valid || !bonus?.valid) return 0;

      const bonusPerMode = field?.value * +convertNumber(bonus.value, 'div');

      return bonusPerMode ? Math.round(bonusPerMode) : 0;
    },
    [form]
  );

  return (
    <Styled.PointsForm {...rest} data-disabled={!pointsAreEnabled}>
      <div aria-live="polite" className="kinto-points-form__fields">
        <fieldset>
          <legend>{parse(t(POINTS_TRANSLATIONS['pointsPerJourney']))}</legend>

          <Each
            of={POINT_FIELDS}
            render={({ name, placeholder, switchLabel }, index: number) => (
              <div className="kinto-points-form__field">
                <FormField
                  className="kinto-points-form__points-switch"
                  name={`${name}_enabled`}
                  label={t(switchLabel)}
                  disabled={!pointsAreEnabled}
                  component={SwitchField}
                  {...(index === 0 && {
                    validate: validations.enabledPoints,
                  })}
                />

                <div
                  className={classNames('kinto-points-form__input-fields', {
                    'kinto-points-form__input-fields--disabled':
                      pointsAreEnabled &&
                      !pointsFormValues?.[`${name}_enabled`],
                  })}
                >
                  <FormField
                    name={name}
                    component={InputField}
                    inputMode="numeric"
                    required
                    aria-disabled={!pointsFormValues?.[`${name}_enabled`]}
                    disabled={
                      !pointsAreEnabled ||
                      !pointsFormValues?.[`${name}_enabled`]
                    }
                    placeholder={t(`${placeholder}`)}
                    requiredLabel={t('global-input_field_required_label')}
                    validate={pointsValidation}
                  />
                  <span className="kinto-points-form__input-fields__bonus">
                    <div className="kinto-visually-hidden">
                      +
                      {t(TABLE_ARIA_KEYS[index], {
                        count: calculateBonusPoints(name),
                      })}
                    </div>

                    {/* we must hide this label to prevent double reading from voice over */}
                    <div aria-hidden>{`+${calculateBonusPoints(name)}`}</div>
                  </span>
                </div>
              </div>
            )}
          />
        </fieldset>

        {formHasError.current && (
          <SnackBar
            variant="alert_2"
            className="kinto-points-form__warning"
            text={t('enabled_points_warning')}
          />
        )}

        <fieldset>
          <legend data-hidden hidden>
            {t('gamification-points_label_bonus_per_km')}
          </legend>
          <FormField
            required
            className="kinto-points-form__bonus-points"
            requiredLabel={t('global-input_field_required_label')}
            placeholder={t('gamification-points_label_bonus_per_km')}
            name="distance_coefficient"
            type="number"
            inputMode="numeric"
            autoComplete="off"
            aria-invalid={!form?.fields['distance_coefficient']?.valid}
            aria-label={t('gamification-points_label_bonus_per_km')}
            suffixIcon={<Icon name="percent" />}
            component={InputField}
            validate={pointsValidation}
          />
        </fieldset>

        <fieldset>
          <legend data-hidden>{t('global-leaderboards')}</legend>

          <FieldArray name="filters">
            {(config) => {
              return (
                <div className="kinto-points-form__filters">
                  <div className="kinto-points-form__filters__header">
                    <h2 className="kinto-points-form__header__title">
                      {t('global-leaderboards')}
                    </h2>

                    <div className="kinto-points-form__header__description">
                      {t('gamification-subtitle_leaderboards_empty_state')}
                    </div>
                  </div>

                  <Button
                    variant="outline"
                    iconPosition="right"
                    icon={<Icon name="plus" />}
                    className="kinto-points-form__filters__create-btn"
                    aria-label={t('accessibility-add_reward_instance')}
                    onClick={openModal}
                  >
                    {t('global_button_create')}
                  </Button>

                  {open && (
                    <LeaderBoardRangeModal
                      renderAsPortal
                      triggerRef={triggerBtn}
                      activeFilter={activeFilter}
                      onClose={() => {
                        closeModal();
                        setActiveFilterId(undefined);
                      }}
                      onDelete={(id) => {
                        config.remove(
                          config.fields.findIndex(
                            (field) => field.value.id === id
                          )
                        );
                      }}
                      onSave={(filter) => {
                        window.skipSubmit = true;

                        const existingFilter = config.fields.find(
                          (field) => field.value.id === activeFilterId
                        );

                        if (!existingFilter) {
                          config.add({ ...filter, id: uniqueId() });
                          return;
                        }

                        const updatedFields = config.fields.map((field) =>
                          field.value.id === existingFilter.value.id
                            ? { ...filter, id: uniqueId() }
                            : field.value
                        );

                        form?.updateValueField('filters', updatedFields);
                      }}
                    />
                  )}

                  <Each
                    containerAs="div"
                    className="kinto-points-form__filters__container"
                    of={config.fields}
                    render={(field) => {
                      return (
                        <LeaderBoardRangeCard
                          {...field.value}
                          onClick={(e) => {
                            setActiveFilterId(field.value.id);
                            openModal(e as MouseEvent<HTMLButtonElement>);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              );
            }}
          </FieldArray>
        </fieldset>
      </div>

      {/* Save button is visible only if pointsAreEnabled */}
      {pointsAreEnabled && (
        <FormActions className="kinto-points-form__actions">
          <Button
            type="submit"
            id="submit_community_details"
            disabled={
              !form?.isFormChanged(['organisation_id']) || !form?.syncFormValid
            }
          >
            {t('CommuteDetails_saving_message')}
          </Button>
        </FormActions>
      )}
    </Styled.PointsForm>
  );
};

export default PointsForm;
