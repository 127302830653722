import styled, { css } from 'styled-components';
import { flex, fontSize, phablet, size } from '@faxi/web-css-utilities';
import { Modal, theme } from '@faxi/web-component-library';

export const EmailListModal = styled(Modal)`
  .wcl-modal {
    &__main {
      padding: ${theme.sizes.SIZE_32};
      padding-top: ${theme.sizes.SIZE_4};
    }
  }

  .email-list-modal {
    &__body {
      ${flex('column')};

      ${phablet(
        css`
          width: 100%;
        `
      )};

      &__delete-btn {
        ${size(theme.sizes.SIZE_32)};
        min-height: unset;
      }

      &--padded-right {
        padding-right: ${theme.sizes.SIZE_16};
      }

      &__search {
        margin-bottom: ${theme.sizes.SIZE_12};
      }

      &__no-results {
        color: var(--SHADE_1_2);
        font-weight: 500;
        text-align: center;
      }

      &__row {
        ${flex('row', 'space-between', 'center')}

        padding: ${theme.sizes.SIZE_12} 0;

        &__email {
          ${fontSize(theme.fontSizes.FONT_16)};
          color: var(--SHADE_1_2);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &--invalid {
            color: var(--ALERT_ERROR_1_1);
          }
        }

        &__remove {
          color: var(--PRIMARY_1_1);
        }

        &:last-of-type {
          margin-bottom: ${theme.sizes.SIZE_12};
        }
      }

      > hr {
        margin: ${theme.sizes.SIZE_10} 0;
      }
    }

    &__search {
      margin-bottom: ${theme.sizes.SIZE_16};
    }

    &__emails {
      max-height: ${theme.sizes.SIZE_616};
      overflow: auto;
      padding-right: ${theme.sizes.SIZE_12};
      margin-right: -${theme.sizes.SIZE_20};

      &__empty-state {
        color: var(--SHADE_1_2);
        margin-top: ${theme.sizes.SIZE_24};
      }
    }
  }
`;
