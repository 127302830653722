import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  FormField,
  FormRef,
  useFormRefValues,
  Form,
  validators,
} from '@faxi/web-form';

import config from 'config';
import { UserContext } from 'store';
import { dateString } from 'utils';
import { LeaderBoardFilterType } from 'models';
import { CalendarField, FormFooter, InputField } from 'components';

import * as Styles from './LeaderBoardRangeModal.styles';

const validateDateDifference =
  (message: string) => (end_date: Dayjs, fields: any) => {
    const start_date = fields['startDate'].value as Dayjs;
    const validDiff = end_date.diff(start_date, 'year', true) <= 2;

    if (validDiff) return undefined;
    else return message;
  };

type LeaderBoardRangeModalFormType = {
  name: string;
  startDate: string;
  endDate: string;
};

type LeaderBoardRangeModalProps = ModalProps & {
  activeFilter?: LeaderBoardFilterType;
  onSave: (filter: LeaderBoardFilterType) => void;
  onDelete: (id: string) => void;
};

const LeaderBoardRangeModal: FC<LeaderBoardRangeModalProps> = (props) => {
  const { className, activeFilter, onSave, onDelete, ...rest } = props;

  const { t } = useTranslation();
  const { userPreferences } = useContext(UserContext);
  const { prompts } = useUtilities();

  const initialData = useMemo(
    () =>
      activeFilter !== undefined
        ? {
            name: activeFilter.name,
            startDate: dayjs(
              dateString({
                date: activeFilter?.startDate,
                format: config.dateFormat,
              }),
              config.dateFormat
            ),
            endDate: dayjs(
              dateString({
                date: activeFilter?.endDate,
                format: config.dateFormat,
              }),
              config.dateFormat
            ),
          }
        : { startDate: dayjs(), endDate: dayjs().add(30, 'days') },
    [activeFilter]
  );

  const isEditing = useMemo(
    () => initialData.name !== undefined,
    [initialData]
  );

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const end_date = useFormRefValues(form, 'endDate')?.endDate;
  const start_date = useFormRefValues(form, 'startDate')?.startDate;

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('name') })
        ),
        validators.general.maxLength(
          20,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '20',
          })
        ),
      ],
      startDate: [validators.general.required(t('required_start_date'))],
      endDate: [
        validators.general.required(t('required_end_date')),
        validateDateDifference(t('validation-dates_two_years_difference')),
      ],
    }),
    [t]
  );

  const handleSubmit = useCallback(
    async (values: LeaderBoardRangeModalFormType) => {
      const filterData = {
        name: values.name,
        startDate: dayjs(values.startDate).format(config.apiDateFormat),
        endDate: dayjs(values.endDate).format(config.apiDateFormat),
      } as LeaderBoardFilterType;

      onSave(filterData);
      modalRef.current?.close();
    },
    [onSave]
  );

  const formWrapper = useCallback(
    (props: PropsWithChildren<{ className: string }>) => (
      <Form
        ref={formRef}
        children={props.children}
        initialData={initialData}
        className={props.className}
        onSubmit={handleSubmit}
      />
    ),
    [initialData, formRef, handleSubmit]
  );

  return (
    <Styles.LeaderBoardModal
      ref={modalRef}
      renderAsPortal
      childrenWrapper={formWrapper}
      className={classNames('kinto-leader-board-modal', className)}
      title={t(
        isEditing
          ? 'gamification-title_edit_leaderboard'
          : 'gamification-label_create_a_leaderboard',
        { name: activeFilter?.name }
      )}
      subtitle={t('gamification-subtitle_modal_create_leaderboard')}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: 'Modal',
      })}
      footer={
        <FormFooter
          modalRef={modalRef}
          cancelLabel={t('cancel')}
          submitLabel={isEditing ? t('update') : t('global_button_create')}
          {...(isEditing && {
            onDelete: async (e) => {
              const proceed = await prompts.delete({
                title: t('gamification-dialog_edit_leaderboard_delete', {
                  name: activeFilter?.name,
                }),
                confirmButtonText: t('Discovery_map_delete'),
                cancelButtonText: t('cancel'),
                triggerRef: e.target as HTMLButtonElement,
              });

              if (!proceed) return;
              onDelete?.(activeFilter?.id!);
              modalRef.current?.close();
            },
          })}
        />
      }
      {...rest}
    >
      <fieldset>
        <legend data-hidden>{t('global-name')}</legend>
        <FormField
          required
          name="name"
          component={InputField}
          autoComplete="off"
          placeholder={t('global-name')}
          validate={validations.name}
        />
      </fieldset>

      <fieldset>
        <legend className="calendar-labels">{t('start')}</legend>
        <FormField
          name="startDate"
          renderAsPortal
          component={CalendarField}
          placeholder={t('campaign-start_date')}
          rightLimitDate={dayjs(end_date)}
          leftLimitDate={(dayjs(end_date) || dayjs())?.subtract(2, 'years')}
          dateFormat={userPreferences.dateFormat}
          validate={validations.startDate}
        />
      </fieldset>

      <fieldset>
        <legend className="calendar-labels">{t('end')}</legend>
        <FormField
          name="endDate"
          renderAsPortal
          component={CalendarField}
          leftLimitDate={dayjs(start_date)}
          rightLimitDate={(dayjs(start_date) || dayjs()).add(2, 'years')}
          placeholder={t('campaign-end_date')}
          dateFormat={userPreferences.dateFormat}
          validate={validations.endDate}
        />
      </fieldset>
    </Styles.LeaderBoardModal>
  );
};

export default LeaderBoardRangeModal;
