import { FC, Fragment, useCallback, useContext, useMemo } from 'react';
import {
  Button,
  ProgressBar,
  config,
  getColor,
  useEffectOnceWhen,
  useUtilities,
  useModalUtilities,
  Menu,
  ButtonProps,
} from '@faxi/web-component-library';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { Icon, SeparatedText } from 'components';
import { convertKgToTonsIfNeeded, dateStringLocale, pluralKey } from 'utils';

import {
  APIResponse,
  SurveyReportType,
  Survey,
  SURVEY_STATUS,
  TransportReport,
} from 'models';
import { UserContext } from 'store';
import { useCallbackAsync, useQuery } from 'hooks';
import { mapSurvey, SURVEY_MOD_OF_TRANSPORT } from '../../utils';
import Each from 'helpers/Each';
import apiSurvey from 'modules/api/apiSurvey';
import ReportCard from './components/ReportCard';
import SustainabilityContext from '../../providers/Sustainability/Sustainability.context';
import PDFModal from '../../components/PDFModal';
import CalculatedModal from './components/CalculatedModal';

import * as Styles from './SurveyReport.styles';

const SurveyReport: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { prompts, showSnackBar } = useUtilities();

  const { surveyId, organisationId } = useParams() as {
    surveyId: string;
    organisationId: string;
  };

  const {
    userPreferences: { unit },
  } = useContext(UserContext);

  const {
    surveyReportRetry,
    activeSurvey: survey,
    openModal: openSurveyModal,
    updateSurvey,
    setActiveSurveyId,
  } = useContext(SustainabilityContext);

  const {
    open,
    triggerRef,
    openModal: openPdfModal,
    closeModal: closePdfModal,
  } = useModalUtilities();

  const {
    open: howCalculatedOpen,
    triggerRef: howCalculatedRef,
    openModal: openHowCalculated,
    closeModal: closeHowCalculated,
  } = useModalUtilities();

  const modalBtnRef = triggerRef.current as HTMLButtonElement;
  const howCalculatedBtn = howCalculatedRef.current as HTMLButtonElement;

  const disabledActions = useMemo(
    () =>
      survey?.status === SURVEY_STATUS.FINISHED ||
      survey?.status === SURVEY_STATUS.CANCELED,
    [survey]
  );

  const surveyReportQuery = useCallback(
    async (signal: () => AbortSignal) =>
      await apiSurvey.getSurvey(surveyId, organisationId, unit, {
        signal: signal(),
      }),
    [surveyId, organisationId, unit]
  );

  const {
    data: surveyReport,
    loading,
    retry,
    setData: setSurveyReport,
  } = useQuery<APIResponse<Survey>, Survey>({
    showSpinner: true,
    initialLoading: true,
    spinnerParent: '.main-template__main',
    deps: [surveyReportQuery],
    queryFn: surveyReportQuery,
    mappingFunction: async (res) => mapSurvey(res.data),
  });

  const [handleFinishSurvey] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.main-template__main',
    condition: async (event) =>
      await prompts.delete({
        buttonIcon: 'circle-stop',
        className: 'end-survey-prompt',
        content: t('survey-body_end_this_survey'),
        confirmButtonText: t('global-end_survey'),
        cancelButtonText: t('cancel'),
        triggerRef: event.target as HTMLButtonElement,
      }),
    callback: async () => {
      const {
        data: { data: stoppedSurvey },
      } = await apiSurvey.stopSurvey(`${survey?.id}`, organisationId);

      const mappedSurvey = mapSurvey(stoppedSurvey);
      setSurveyReport((old) => ({ ...old, ...mappedSurvey }));
      updateSurvey(surveyId, mappedSurvey);

      showSnackBar({
        text: t('survey-snack_bar_survey_ended'),
        variant: 'success',
      });
    },
  });

  const surveyDates = useMemo(
    () => [
      {
        name: t('campaign-start_date'),
        value: dateStringLocale(
          surveyReport?.start_date,
          surveyReport?.start_time,
          config.dateFormatSimple
        ),
      },
      {
        name: t('campaign-end_date'),
        value: dateStringLocale(
          surveyReport?.end_date,
          surveyReport?.end_time,
          config.dateFormatSimple
        ),
      },
    ],
    [surveyReport, t]
  );

  const surveyStats = useMemo(
    () =>
      surveyReport
        ? ({
            driving: surveyReport.driving,
            carpooling: surveyReport.carpooling,
            walking: surveyReport.walking,
            cycling: surveyReport.cycling,
            corporate_shuttle: surveyReport.corporate_shuttle,
            bus: surveyReport.bus,
            train: surveyReport.train,
          } as Partial<SurveyReportType>)
        : {},
    [surveyReport]
  );

  //Days left when survey starts
  const daysLeft = useMemo(
    () =>
      dayjs(surveyReport?.end_date).diff(
        dayjs(surveyReport?.start_date),
        'day'
      ),
    [surveyReport]
  );

  useEffectOnceWhen(() => {
    surveyReportRetry.current = retry;
    setActiveSurveyId(surveyId);
  }, !!surveyId);

  const headerMenuActions = useMemo(
    () =>
      [
        ...(surveyReport?.status === 'active' ||
        surveyReport?.status === 'pending'
          ? [
              {
                children: t('Edit name and date'),
                icon: <Icon name="pen" />,
                disabled: disabledActions,
                onClick: async (e) => {
                  setActiveSurveyId(surveyId);
                  openSurveyModal(e);
                },
              },
            ]
          : []),
        {
          children: t('View PDF'),
          icon: <Icon name="file-pdf" />,
          onClick: openPdfModal,
        },
        ...(surveyReport?.status === 'active'
          ? [
              {
                children: t('global-end_survey'),
                icon: <Icon name="circle-stop" />,
                variant: 'delete-ghost',
                onClick: handleFinishSurvey,
              },
            ]
          : []),
      ] as ButtonProps[],

    [
      t,
      disabledActions,
      openPdfModal,
      surveyReport,
      handleFinishSurvey,
      setActiveSurveyId,
      surveyId,
      openSurveyModal,
    ]
  );

  if (loading) return null;

  return (
    <Styles.SurveyReport className={classNames('kinto-page', 'sustainability')}>
      <div className="kinto-survey-report">
        <Button
          variant="ghost"
          icon={<Icon name="chevron-left" />}
          onClick={() => navigate('..')}
        >
          {t('Back')}
        </Button>

        <div className="kinto-survey-report__header">
          <h1 className="kinto-survey-report__title">{survey?.name}</h1>

          <div className="kinto-survey-report__header__actions">
            {!disabledActions && (
              <Button onClick={() => navigate(`send/${survey?.name}`)}>
                {t('global-button_send_via_email')}
              </Button>
            )}

            <Menu
              triggerProps={{
                variant: 'outline',
                icon: <Icon name="ellipsis-vertical-solid" />,
                iconPosition: 'right',
              }}
              triggerTitle={''}
              menuItems={headerMenuActions}
              openPosition={'bottom-left'}
            />
          </div>
        </div>

        <div className="kinto-survey-report__content">
          <ReportCard
            className="status"
            icon="clock"
            status={surveyReport?.status}
            title={t('ga_gd_status')}
            {...(surveyReport?.status === SURVEY_STATUS.ACTIVE && {
              footerTitle: t(pluralKey('global-day_left', daysLeft), {
                count: daysLeft,
              }),
            })}
          >
            <Each
              containerAs="div"
              className="kinto-report-card__labels"
              render={(item) => <SeparatedText {...item} />}
              of={surveyDates}
            />
          </ReportCard>

          <ReportCard
            className="audience"
            icon="users"
            title={t('global-audience')}
          >
            <Each
              containerAs="div"
              className="kinto-report-card__labels"
              render={(item) => <SeparatedText {...item} />}
              of={[
                {
                  name: t('sustainability-audience-total_participants'),
                  value: `${surveyReport?.participants_total}`,
                },
                {
                  name: t('sustainability-audience-total_completed'),
                  value: `${surveyReport?.participants_finished}`,
                },
                {
                  name: t('sustainability-audience-new_joiners'),
                  value: `${surveyReport?.newcomers_total}`,
                  tooltip: t('sustainability-new_joiners-tooltip').replace(
                    /\\n/g,
                    '\n'
                  ),
                },
                {
                  name: `${t('sustainability-audience-new_joiners_completed')}`,
                  value: `${surveyReport?.newcomers_finished}`,
                },
                {
                  name: t('sustainability-completed_web_surveys'),
                  value: `${surveyReport?.public_inputs_total}`,
                },
              ]}
            />
          </ReportCard>

          <ReportCard
            className={classNames('co2-emission', {
              'co2-emission--not-finished':
                surveyReport?.status !== SURVEY_STATUS.FINISHED,
            })}
            icon="cloud"
            title={t('global-CO2_emissions')}
          >
            {surveyReport?.status === SURVEY_STATUS.FINISHED ? (
              <Fragment>
                <div className="kinto-survey-report__emissions">
                  <Icon className="clouds-icon" name="cloud-fog" />
                  <div className="emission">
                    {convertKgToTonsIfNeeded(surveyReport?.co2).toFixed(2)}
                  </div>
                  <div className="co2">
                    {t('global-tco2e', {
                      unit: `${surveyReport?.co2 > 1000 ? 't' : 'kg'}`,
                    })}
                  </div>
                </div>

                <div className="kinto-survey-report__emissions__desc">
                  {t('report-emissions_info', {
                    count: +convertKgToTonsIfNeeded(surveyReport?.co2).toFixed(
                      2
                    ),
                    unit: `${surveyReport?.co2 > 1000 ? 't' : 'kg'}`,
                  })}
                </div>

                <div className="kinto-survey-report__emissions__desc-second">
                  {t('report-emissions_estimation')}
                </div>

                <div className="kinto-survey-report__emissions__stats">
                  <ProgressBar
                    currentStep={surveyReport?.surveyed_users_percentage || 0}
                    numberOfSteps={100}
                    caption={t('global-user_data')}
                    variant="rich"
                    color={getColor('--SECONDARY_4_1')}
                  />

                  <ProgressBar
                    currentStep={surveyReport?.predicted_users_percentage || 0}
                    numberOfSteps={100}
                    caption={t('global-estimation')}
                    variant="rich"
                    color={getColor('--SECONDARY_6_1')}
                  />

                  <Button
                    variant="ghost"
                    className="kinto-survey-report__how-is-calculated"
                    onClick={openHowCalculated}
                  >
                    {t('report-emissions_link_calculations')}
                  </Button>
                </div>
              </Fragment>
            ) : (
              <div className="kinto-survey-report__emissions-not-finished">
                <div className="kinto-survey-report__emissions-not-finished__icon">
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                </div>
                <div>{t('sustainability-info_not_available')}</div>
              </div>
            )}
          </ReportCard>

          <ReportCard
            className="commuting-pattern"
            icon="grid-round-2"
            title={t('global-commuting_pattern')}
          >
            <Each
              containerAs="div"
              className="kinto-report-card__labels"
              render={(item) => <SeparatedText {...item} boldSecond />}
              of={[
                {
                  name: t('sustainability-working_pattern_working_from_home'),
                  value: `${surveyReport?.remote_percent}%`,
                },
                {
                  name: t('fuel_type-label_hybrid'),
                  value: `${surveyReport?.hybrid_percent}%`,
                },
                {
                  name: t('sustainability-working_pattern_going_to_the_office'),
                  value: `${surveyReport?.on_site_percent}%`,
                },
              ]}
            />
          </ReportCard>

          <ReportCard
            className="mode-of-transport"
            icon="car-bus"
            title={t('mode_of_transport')}
          >
            <div className="kinto-survey-report__stats__units">
              <div>
                {t('units')} ({t(unit === 'km' ? 'km' : 'miles')})
              </div>
              <div>{t('users')}</div>
            </div>

            <Each
              containerAs="div"
              className="kinto-survey-report__stats"
              of={Object.entries(surveyStats)}
              render={([key, report]) => (
                <div className="kinto-survey-report__stats__item">
                  <div>{t(SURVEY_MOD_OF_TRANSPORT[key])}</div>
                  <div>{(report as TransportReport)?.distance}</div>
                  <div>{(report as TransportReport)?.users}</div>
                </div>
              )}
            />
          </ReportCard>

          <ReportCard
            className={classNames('travel-distance', {
              'travel-distance--not-finished':
                surveyReport?.status !== SURVEY_STATUS.FINISHED,
            })}
            icon="map-location-dot"
            title={t('sustainability-report-average_travel_distance')}
            footerTitle={`${surveyReport?.average_distance?.toFixed(2)} ${t(
              'km'
            )}`}
          />

          <ReportCard
            className="days-on-site"
            icon="calendar-days"
            title={t('sustainability-report-average_days_on_site')}
            footerTitle={t('global-days_amount_per_month', {
              count: surveyReport?.average_on_site,
            })}
          />
          {/* <ReductionAnalysis
          className="reduction-analysis"
          status={surveyReport.status}
        /> */}
        </div>

        {open && (
          <PDFModal
            survey={surveyReport}
            triggerRef={modalBtnRef}
            onClose={closePdfModal}
          />
        )}

        {howCalculatedOpen && (
          <CalculatedModal
            triggerRef={howCalculatedBtn}
            onClose={closeHowCalculated}
          />
        )}
      </div>
    </Styles.SurveyReport>
  );
};

export default SurveyReport;
