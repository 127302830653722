import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Sidebar,
  SidebarItem,
  useResize,
  useScrollDirection,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { appUri } from 'config';
import { InnerFooter, ScrollToTop } from 'components';
import Styled from 'components/_layouts/Containers';
import { hideFooterOnPages } from 'modules';
import { Community } from 'models';
import { UserContext } from 'store';
import { INameExtended } from 'components/Icon';
import {
  SidebarAriaLabels,
  SidebarItemsConfig,
} from '@faxi/web-component-library/build/components/_organisms/Sidebar/Sidebar.component';
import SkipLink from 'components/_molecules/SkipLink';
import { responsiveSm } from '@faxi/web-css-utilities';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CommunityHandler from 'router/components/CommunityHandler';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import { PERMISSIONS_MAP } from '../../../store/UserProvider/User.provider';
import OnboardingStepsSidebar from '../../_molecules/OnboardingStepsSidebar';

const MainTemplate: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const isPhablet = useResize(responsiveSm);

  const {
    communities,
    user,
    communityId,
    adminForCommunity,
    premiumFeatureAvailable,
    isAdminAtLeastOnce,
    onboardingStep,
  } = useContext(UserContext);

  const [selectedCommunity, setSelectedCommunity] = useState<
    Omit<Community, 'id'> & { id: string }
  >();

  const location = useLocation();
  const navigate = useNavigate();

  const { isScrollingDown, containerRef } =
    useScrollDirection<HTMLDivElement>();

  const hideFooter = useMemo(() => {
    const locationPathnameClean = location.pathname.replace(
      new RegExp('[0-9]+\\-?[0-9]*', 'g'),
      ''
    );

    return hideFooterOnPages.some(
      (route) =>
        locationPathnameClean ===
        (typeof appUri[route] === 'string'
          ? (appUri[route] as string)
          : (appUri[route] as any)()
        ).replace(new RegExp(':[a-zA-Z]+', 'g'), '')
    );
  }, [location.pathname]);

  const organisations = useMemo<(Omit<Community, 'id'> & { id: string })[]>(
    () =>
      communities.map((org) => ({
        ...org,
        id: `select_community_${org.id}`,
      })),
    [communities]
  );

  const splitUrl = useMemo(
    () => location.pathname.split('/') || [],
    [location.pathname]
  );

  const logoTo = useMemo(() => `/community/${communityId}/home`, [communityId]);

  const communityIndex = useMemo(
    () => splitUrl.findIndex((str) => str === 'community'),
    [splitUrl]
  );

  const userData = useMemo(
    () => ({
      name:
        user?.firstname || user?.name
          ? `${user?.firstname} ${user?.name}`
          : `${user?.login || ''}`,
      image_url: user?.image_url || '/assets/svg/user_circle_placeholder.svg',
      image_alt: t('user_profile_picture', { user: user?.firstname }),
    }),
    [user, t]
  );

  const sidebarUrl = useCallback(
    (cid: string) => {
      const modifiedPath = location.pathname.includes('community')
        ? location.pathname.replace(/^\/(admin\/)?community\/[^/]+/, '')
        : '';
      return `/community/${cid}${modifiedPath}`;
    },
    [location]
  );

  const topItems = useMemo(
    () =>
      organisations?.map(
        ({ id, name, pending_requests, image_url }) =>
          ({
            id,
            name,
            altText: `${name} ${t('community_image')}`,
            pending: !!pending_requests,
            image_url,
            iconName: 'users',
            url: sidebarUrl(id.split('_').at(-1)!),
          } as SidebarItem<INameExtended>)
      ),
    [organisations, sidebarUrl, t]
  );

  const userItems = useMemo<SidebarItem<INameExtended>[]>(
    () => [
      {
        key: 'home',
        name: t('mHome'),
        iconName: 'house',
        url:
          communities.length === 0 ? `/home` : `/community/${communityId}/home`,
        id: 'nav_home',
      },
    ],
    [communities.length, communityId, t]
  );

  const adminItems = useMemo<SidebarItem<INameExtended>[]>(
    () => [
      {
        name: t('header_dashboard'),
        iconName: 'browser',
        url: `/community/${communityId}/admin/dashboard`,
        key: 'dashboard',
        id: 'nav_dashboard',
      },
      {
        name: t('mPeople'),
        iconName: 'users',
        url: `/community/${communityId}/admin/people`,
        key: 'people',
        id: 'nav_people',
      },
      ...(premiumFeatureAvailable('PARKING_BARRIER')
        ? [
            {
              name: t('global-parking_management'),
              iconName: 'square-parking' as INameExtended,
              url: `/community/${communityId}/admin/parking-management`,
              key: 'parking',
              id: 'nav_parking',
            },
          ]
        : []),
      ...(premiumFeatureAvailable('BOOKING_PAGE')
        ? [
            {
              name: t('mBookings'),
              iconName: 'calendar-clock',
              url: `/community/${communityId}/admin/bookings`,
              key: 'bookings',
              id: 'nav_bookings',
            } as SidebarItem<INameExtended>,
          ]
        : []),
      {
        name: t('header_reports'),
        iconName: 'chart-simple',
        url: `/community/${communityId}/admin/reports`,
        key: 'reports',
        id: 'nav_reports',
      },
      {
        name: t('leaderboards'),
        iconName: 'trophy-star' as INameExtended,
        url: `/community/${communityId}/admin/leaderboards`,
        key: 'leaderboards',
        id: 'nav_leaderboards',
      },
      ...(premiumFeatureAvailable('MAP_PAGE')
        ? [
            {
              name: t('mMap'),
              iconName: 'map-location-dot',
              url: `/community/${communityId}/admin/map`,
              key: 'map',
              id: 'nav_map',
            } as SidebarItem<INameExtended>,
          ]
        : []),
      ...(premiumFeatureAvailable('GAMIFICATION_FEATURE')
        ? [
            {
              name: t('global-gamification'),
              iconName: 'gamepad-modern',
              url: `/community/${communityId}/admin/gamification`,
              id: 'nav_gamification',
            } as SidebarItem<INameExtended>,
          ]
        : []),
      ...(premiumFeatureAvailable('SURVEY')
        ? [
            {
              id: 'nav_sustainability',
              iconName: 'leaf',
              name: t('global-sustainability'),
              url: `/community/${communityId}/admin/sustainability`,
            } as SidebarItem<INameExtended>,
          ]
        : []),
      ...(premiumFeatureAvailable('EVENT_FEATURE')
        ? [
            {
              id: 'nav_events',
              iconName: 'calendar-week',
              name: t('events-title_events'),
              url: `/community/${communityId}/admin/events`,
            } as SidebarItem<INameExtended>,
          ]
        : []),
      {
        name: t('mMessages'),
        iconName: 'message-lines',
        url: `/community/${communityId}/admin/messages`,
        key: 'messages',
        id: 'nav_messages',
      },
      {
        name: t('navigation-more'),
        id: 'nav_community_settings',
        iconName: 'ellipsis',
        children: [
          {
            id: 'nav_community_settings-community-details',
            name: t('ga_group_details'),
            url: `/community/${communityId}/admin/settings/community-details`,
          },
          {
            id: 'nav_community_settings-custom-information',
            name: t('custom_information'),
            url: `/community/${communityId}/admin/settings/community-custom-info`,
          },
          {
            id: 'nav_community_settings-car-park-settings',
            name: t('car_park_settings'),
            url: `/community/${communityId}/admin/settings/car-park-settings`,
          },
          {
            id: 'nav_community_settings-predefined-addresses',
            name: t('predefined-addresses-section_title'),
            url: `/community/${communityId}/admin/settings/community-predefined-addresses`,
          },
          {
            id: 'nav_community_settings-preapproved-settings',
            name: t('preapproved_settings'),
            url: `/community/${communityId}/admin/settings/community-preapproved-settings`,
          },
          {
            id: 'nav_community_settings-predefined-shifts',
            name: t('predefined_shifts-predefined_shifts'),
            url: `/community/${communityId}/admin/settings/predefined-shifts`,
          },
        ],
      },
    ],
    [t, communityId, premiumFeatureAvailable]
  );

  const userSettingsItems = useMemo<SidebarItem<INameExtended>[]>(
    () =>
      onboardingStep < 3
        ? [
            {
              name: t('account-personal_details_update_profile'),
              url: '/account-settings/update-profile',
              id: 'account-settings_update-profile',
            },
          ]
        : [
            {
              name: t('account-personal_details_update_profile'),
              url: '/account-settings/update-profile',
              id: 'account-settings_update-profile',
            },
            {
              name: t('account-personal_details_update_address'),
              url: '/account-settings/update-address',
              id: 'account-settings_update-address',
            },
            {
              name: t('account-personal_details_change_email'),
              url: '/account-settings/change-email',
              id: 'account-settings_change-email',
            },
            {
              name: t('change_password'),
              url: '/account-settings/password',
              id: 'account-settings_password',
            },
            {
              name: t('user_preferences'),
              url: '/account-settings/user-preferences',
              id: 'account-settings_user-preferences',
            },
          ].concat(
            ...(isAdminAtLeastOnce
              ? [
                  {
                    name: t('settings_notification'),
                    url: '/account-settings/email-notifications',
                    id: 'account-settings_email-notifications',
                  },
                ]
              : [])
          ),
    [isAdminAtLeastOnce, onboardingStep, t]
  );

  const selectedItem = useMemo(
    () =>
      ({
        id: selectedCommunity?.id,
        name: selectedCommunity?.name,
        image_url: selectedCommunity?.image_url,
        iconName: 'users',
        altText: `${selectedCommunity?.name} ${t('community_image')}`,
      } as SidebarItem<INameExtended>),
    [selectedCommunity, t]
  );

  const ariaLabels = useMemo(
    () =>
      ({
        ariaCollapse: t('accessibility-button_collapse', {
          name: t('global-sidebar').toLowerCase(),
        }),
        ariaExpand: t('accessibility-button_expand', {
          name: t('global-sidebar').toLowerCase(),
        }),
        ariaCloseMenu: t('accessibility-button_collapse', {
          name: t('global-sidebar').toLowerCase(),
        }),
        ariaOpenMenu: t('accessibility-button_expand', {
          name: t('global-sidebar').toLowerCase(),
        }),
        ariaLogoLabel: t('app_name'),
        ariaDeleteLabel: t('Discovery_map_delete'),
      } as SidebarAriaLabels),
    [t]
  );

  const navigationItems = useMemo(
    () =>
      [{ items: userItems }].concat(
        adminForCommunity
          ? [
              {
                itemsTitle: t('navigation-title_admin_controls'),
                items: adminItems,
              } as SidebarItemsConfig<INameExtended>,
            ]
          : []
      ) as SidebarItemsConfig<INameExtended>[],
    [t, adminForCommunity, adminItems, userItems]
  );

  const handleCommunityRole = useCallback(
    (item: SidebarItem<string>) => {
      const communityId = item.id.split('_').at(-1)!;

      const community = user?.organisations?.find(
        (org) => org.id === communityId
      );

      const isAdmin = community?.rightprofile === 'A';
      storageService.setItem(STORAGE_KEYS.COMMUNITY_ID, communityId);
      storageService.setItem(
        STORAGE_KEYS.ROLE,
        isAdmin ? ['user', 'admin'] : ['user']
      );
    },
    [user]
  );

  const onboardingStatusItem = useCallback(
    (expanded: boolean) => {
      return (
        <div
          className={classNames({
            'main-template__sidebar__expanded-onboarding-status': expanded,
          })}
        >
          <OnboardingStepsSidebar
            steps={[
              t('steps_account-label_account_created'),
              t('account-personal_details_update_profile'),
              t('account-personal_details_update_address'),
            ]}
            activeStep={onboardingStep}
            className={classNames({ collapsed: !expanded })}
          />
        </div>
      );
    },
    [onboardingStep, t]
  );

  useEffect(() => {
    const community = organisations.find(
      ({ id }) => +id.replace('select_community_', '') === communityId
    );

    setSelectedCommunity(community);
  }, [communityId, communityIndex, organisations, splitUrl]);

  useEffect(() => {
    const prevRoles = storageService.getItem(STORAGE_KEYS.ROLE);
    const newRoles = PERMISSIONS_MAP[Number(adminForCommunity)];

    if (newRoles === prevRoles) return;
    storageService.setItem(STORAGE_KEYS.ROLE, newRoles);
  }, [adminForCommunity]);

  return (
    <Styled.TemplateGrid
      className={classNames('main-template', {
        'scrolling-down': isScrollingDown,
      })}
    >
      <>
        {!isPhablet && (
          <SkipLink to="#sidebar">
            {t('accessibility-button_skip_to_sidebar')}
          </SkipLink>
        )}
        <SkipLink to="#main">
          {t('accessibility-button_skip_to_content')}
        </SkipLink>
        <SkipLink to="#footer">
          {t('accessibility-button_skip_to_footer')}
        </SkipLink>
      </>

      <Sidebar
        id="sidebar"
        className="main-template__sidebar"
        logoTo={logoTo}
        logoAlt={t('app_name')}
        searchPlaceholder={t('selgroup_search')}
        topItemsTitle={t('Discovery_map_groups')}
        noResultsLabel={t('search_no_results_found')}
        user={userData}
        topItems={topItems}
        mainItems={navigationItems}
        bottomItems={userSettingsItems}
        selectedItem={selectedItem}
        ariaLabels={ariaLabels}
        logoUrl="/assets/svg/kinto_join_light.svg"
        logoutText={t('navigation-dropdown_button_log_out')}
        onClickLogout={() => {
          navigate(`/${appUri.LOGGING_OUT}`);
        }}
        onItemClick={handleCommunityRole}
        onboardingStatusItem={(expanded) =>
          onboardingStep && onboardingStep < 3 && onboardingStatusItem(expanded)
        }
      />

      <div
        ref={containerRef}
        id="main-container"
        className="main-template__content-wrapper"
      >
        <ScrollToTop targetNodeId="main-container" />

        <main id="main" role="main" className="main-template__main">
          <CommunityHandler>
            <Outlet />
          </CommunityHandler>
        </main>

        {!hideFooter && <InnerFooter className="main-template__footer" />}
      </div>
    </Styled.TemplateGrid>
  );
};

export default MainTemplate;
