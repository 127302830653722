import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
  ${flex('row', 'space-between')};

  height: 100%;
  margin: 0 auto;
  gap: ${theme.sizes.SIZE_80};
  width: clamp(${theme.sizes.SIZE_504}, 100%, 1500px);
  padding: 0 ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_80};

  h1 {
    word-break: break-word;
  }

  ${phablet(css`
    width: unset;
    margin: unset;
    flex-direction: column;
    padding: 0 ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24};
  `)};

  .kinto-survey-main {
    &__left-section,
    &__right-section {
      ${flex('column')};
      flex: 0 1 50%;
    }

    &__left-section {
      color: var(--SHADE_1_1);
      max-width: ${theme.sizes.SIZE_504};

      .form__actions {
        ${flexGap(theme.sizes.SIZE_16, 'row')};

        max-width: ${theme.sizes.SIZE_320};
        margin-top: ${theme.sizes.SIZE_80};

        .button {
          flex: 1;
        }

        ${phablet(css`
          ${flexGap('0px', 'column')};

          max-width: unset;
          flex-direction: row;
        `)}
      }

      &__survey-form-step {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        font-weight: 400;
        color: var(--PRIMARY_1_1);
      }

      &__heading {
        ${fontSize(theme.fontSizes.FONT_44)};
        color: var(--SECONDARY_1_1);

        ${phablet(css`
          ${fontSize(theme.fontSizes.FONT_24)};
        `)};
      }

      &__mobile-image {
        display: none;

        ${phablet(css`
          display: initial;
          margin: auto 0;
          width: 100%;
          max-height: ${theme.sizes.SIZE_374};
          padding: ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_32} 0;
        `)};
      }

      &__subtitle {
        ${fontSize(theme.fontSizes.FONT_34)};

        font-weight: 600;
        color: var(--SECONDARY_1_1);
        margin-top: ${theme.sizes.SIZE_64};

        ${phablet(css`
          ${fontSize(theme.fontSizes.FONT_20)};

          margin-top: ${theme.sizes.SIZE_32};
        `)};
      }

      &__paragraph {
        margin-top: ${theme.sizes.SIZE_64};
        white-space: pre-wrap;
      }

      &__action {
        margin-top: ${theme.sizes.SIZE_32};
      }

      form {
        width: 100%;

        ${phablet(css`
          max-width: unset;
        `)};
      }
    }

    &__right-section {
      ${phablet(css`
        display: none;
      `)};

      img {
        margin: auto 0;
        width: 100%;
        max-height: ${theme.sizes.SIZE_552};
      }
    }
  }

  &.kinto-survey-initial {
    .kinto-survey-main {
      &__left-section {
        &__subtitle {
          font-weight: 500;
        }

        &__paragraph {
          max-width: ${theme.sizes.SIZE_504};
        }

        &__form {
          max-width: ${theme.sizes.SIZE_374};
          margin-top: ${theme.sizes.SIZE_128};

          ${phablet(css`
            max-width: unset;
          `)}
        }
      }

      &__start-btn {
        margin-top: ${theme.sizes.SIZE_48};
      }
    }
  }

  &.kinto-survey-form {
    .kinto-survey-main {
      &__left-section__mobile-image {
        display: none;
      }
    }
  }
`;
