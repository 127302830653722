import { FC, useContext, useMemo } from 'react';
import { Chart } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { ReportsContext, UserContext } from 'store';
import PlaceholderChart from '../PlaceholderChart';
import { useChartDate } from '../hooks';
import { ChartData, ChartAffectedByFiltersType } from 'models';

const VerificationsChart: FC<ChartAffectedByFiltersType> = (
  props: ChartAffectedByFiltersType
): JSX.Element => {
  const { activeDirectionFilters } = props;

  const { kpiData, rangeStatsReportError } = useContext(ReportsContext);

  const { includeTestUsers } = useContext(UserContext);

  const { t } = useTranslation();

  const seriesChartData = useMemo(() => {
    if (
      !kpiData ||
      (includeTestUsers && !kpiData.verifications.testChartData)
    ) {
      return [];
    }

    return [
      {
        name: `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
        data: kpiData?.verifications.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t(
                'total_for_chosen_directions_test'
              )} (${activeDirectionFilters})`,
              data: kpiData?.verifications.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [activeDirectionFilters, includeTestUsers, kpiData, t]);

  const dateObject = useChartDate(seriesChartData);

  return rangeStatsReportError ? (
    <PlaceholderChart />
  ) : (
    <Chart
      id="verifications-chart"
      series={seriesChartData}
      yAxisLabel={t('reports-chart_title_verifications')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('reports-chart_title_verifications'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
      {...props}
    />
  );
};

export default VerificationsChart;
