import { useContext, useEffect } from 'react';
import { AuthContext } from '../store';

const LoggingOut = () => {
  const { handleLogout } = useContext(AuthContext);

  useEffect(() => {
    handleLogout(false);
  }, [handleLogout]);

  return <></>;
};

export default LoggingOut;
