import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  Button,
  config,
  DatePicker,
  getColor,
  Heading,
  Image,
  PAGE_LIMIT,
  Select,
  SelectOption,
  SortOption,
  SORT_OPTIONS,
  Table,
  Tabs,
} from '@faxi/web-component-library';
import { FiltersModal, Icon, InputField, PageLayout } from 'components';
import { useTranslation } from 'react-i18next';
import useOptions from './useOptions';
import dayjs from 'dayjs';
import { reportsConfig } from 'config/reportsConfig';
import { apiReports, LeaderboardGetParamType } from 'modules';
import { DepotFilter, LeaderboardData } from 'models';
import { NoData } from 'Global.styles';
import { useGenerateXLSX, useTablePagination } from 'hooks';
import useJourneyFilter from 'utils/useJourneyFilter';
import { UserContext } from 'store';
import { FiltersButton } from 'components';

import * as Styled from './Leaderboards.styles';

const XLSX_TRANSLATION_MAPPING = {
  point: 'total',
  all: 'total',
  driving: 'cp_map_driving',
  walking: 'cp_map_walking',
  cycling: 'cp_map_cycling',
  passengers: 'A_passenger',
};

const SHOWN_COLUMN_MAPPING = {
  points: ['position', 'id', 'name', 'balance'],
  all: ['position', 'id', 'name', 'jc'],
  driving: ['position', 'id', 'name', 'pdist', 'avg_passengers', 'jc'],
  default: ['position', 'id', 'name', 'pdist', 'jc'],
};

const DEFAULT_COUNT_PER_PAGE = 10;

const itemsKey = ['leaguetable', 'leaguetable-test'];

type LeaderboardType = 'points' | 'journeys';

const INITIAL_LEADERBOARD_SORT = {
  sortBy: 'balance',
  sortDirection: SORT_OPTIONS.DSC,
} as SortOption<LeaderboardData>;

const Leaderboards = () => {
  const {
    communityId,
    userReady,
    userPreferences: { unit, dateFormat },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const [leaderboardType, setLeaderboardType] =
    useState<LeaderboardType>('points');

  const [directionsFilter, setDirectionsFilter] = useState([]);
  const [directionsFiltesModalOpen, setDirectionsFiltesModalOpen] =
    useState(false);

  const [leaderboardTypeParam, setLeaderboardTypeParam] =
    useState<LeaderboardGetParamType>('point');

  const [journeyLeaderboardDateFilter, setJourneyLeaderboardDateFilter] =
    useState('total');

  const [dateRange, setDateRange] = useState({
    from: dayjs().subtract(29, 'day'),
    to: dayjs(),
  });

  // created leaderboards from points page
  const [depotFilters, setDepotFilters] = useState<DepotFilter[]>([]);
  const [selectedDepotFilter, setSelectedDepotFilter] = useState<DepotFilter>();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const { finalOptions } = useOptions({
    pointsActive: leaderboardType === 'points',
    depotFilters,
  });

  const translationKeys = useMemo(
    () =>
      ({
        position: t('position_leaderboard'),
        id: t('ID'),
        name: t('global-name'),
        pdist: t('kilometers_saved'),
        avg_passengers: t('average_passengers'),
        jc: t('number_of_journeys'),
        balance: t('leaderboard-title_number_of_points'),
      } as Record<Partial<keyof LeaderboardData>, string>),
    [t]
  );

  const filters = useJourneyFilter();

  const tabsOptions = useMemo<{ label: string; value: LeaderboardType }[]>(
    () => [
      { label: t('leaderboard-title_number_of_points'), value: 'points' },
      { label: t('number_of_journeys'), value: 'journeys' },
    ],
    [t]
  );

  const {
    data,
    count,
    search,
    totalPages,
    totalCount,
    currentPage,
    activeColumnSort,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<LeaderboardData, 'leaguetable'>({
    itemsKey,
    totalKey: ['total', 'total-test'],
    condition: !!(communityId && userReady),
    initialSortBy: INITIAL_LEADERBOARD_SORT.sortBy,
    initialSortDirection: INITIAL_LEADERBOARD_SORT.sortDirection,
    deps: [communityId, leaderboardType, dateRange],
    resetDeps: [
      leaderboardType,
      communityId,
      leaderboardTypeParam,
      journeyLeaderboardDateFilter,
      directionsFilter,
      dateRange,
    ],
    customErrorMessage: t('no_members_in_community'),
    applyQueryParams: false,
    onDataLoad: (data) => {
      setDepotFilters(data.filters);
    },
    mappingFunction: async (data: LeaderboardData[]) => {
      return data?.map(
        ({
          position,
          user_id,
          image_url,
          first_name,
          last_name,
          pdist,
          jc,
          istest,
          avg_passengers,
          screen_name,
          balance,
        }) => ({
          position: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {position}
            </span>
          ),
          id: user_id,
          name: (
            <div className="table-data-with-image">
              <Image
                className="profile-img"
                src={image_url || ''}
                fallbackUrl="/assets/svg/user_circle_placeholder.svg"
                alt={t('user_profile_picture', { user: first_name })}
              />
              <span className={classNames({ 'kinto-test-data': istest })}>
                {first_name || last_name
                  ? [first_name, last_name].join(' ').trim()
                  : '-'}
              </span>
            </div>
          ),
          pdist: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {pdist}
            </span>
          ),
          avg_passengers: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {avg_passengers || '-'}
            </span>
          ),
          jc: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {jc || '0'}
            </span>
          ),
          balance: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {balance || '0'}
            </span>
          ),
        })
      ) as any;
    },
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction,
      cancelConfig
    ): any =>
      apiReports.leaguetableReport({
        oid: communityId!,
        unit,
        count,
        offset,
        search,
        directions:
          leaderboardType === 'journeys'
            ? directionsFilter.join(',')
            : undefined,
        type: leaderboardType === 'points' ? 'point' : leaderboardTypeParam,
        ...(leaderboardType === 'points' && !Number(leaderboardTypeParam)
          ? { period: 'total' }
          : {
              from: selectedDepotFilter?.data.from,
              to: selectedDepotFilter?.data.to,
            }),
        config: cancelConfig,
        sort_by,
        sort_direction,
        ...(dateRange &&
          leaderboardType === 'journeys' && {
            from: dateRange.from.format(config.apiDateFormat),
            to: dateRange.to.format(config.apiDateFormat),
          }),
      }),
  });

  const handleOnColumnSort = useCallback(
    (sort: SortOption<LeaderboardData>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  const shownColumns = useMemo(
    () =>
      leaderboardType === 'points'
        ? SHOWN_COLUMN_MAPPING.points
        : SHOWN_COLUMN_MAPPING[
            leaderboardTypeParam as keyof typeof SHOWN_COLUMN_MAPPING
          ] || SHOWN_COLUMN_MAPPING.default,

    [leaderboardType, leaderboardTypeParam]
  );

  const columnWidths = useMemo(() => {
    const wCols: Record<string, number> = {
      position: 10,
      id: 10,
      name: 25,
      pdist: 25,
      avg_passengers: 25,
      jc: 25,
      balance: 25,
    };

    const wscols = shownColumns?.map((column) => ({
      wch: wCols[column],
    }));

    return wscols;
  }, [shownColumns]);

  const getLeaderboardDataForXLSX = useCallback(async () => {
    const array: any[][] = [];

    const { leaguetable, 'leaguetable-test': leaguetableTest } =
      await apiReports.leaguetableReport({
        oid: communityId!,
        unit,
        offset: 0,
        search,
        directions:
          leaderboardType === 'journeys'
            ? directionsFilter.join(',')
            : undefined,
        type: leaderboardType === 'points' ? 'point' : leaderboardTypeParam,
        ...(leaderboardType === 'points' && !Number(leaderboardTypeParam)
          ? { period: 'total' }
          : {
              from: selectedDepotFilter?.data.from,
              to: selectedDepotFilter?.data.to,
            }),
        sort_by: activeColumnSort.sortBy as any,
        sort_direction: activeColumnSort.sortDirection,
        ...(dateRange &&
          leaderboardType === 'journeys' && {
            from: dateRange.from.format(config.apiDateFormat),
            to: dateRange.to.format(config.apiDateFormat),
          }),
      });

    const mapLeaderboardData = (leaderboardData: LeaderboardData[]) => {
      (leaderboardData as LeaderboardData[])?.forEach(
        ({
          position,
          user_id,
          first_name,
          last_name,
          balance,
          jc,
          pdist,
          avg_passengers,
        }) => {
          const mapData: Record<string, string | number | undefined> = {
            position,
            id: user_id,
            name: [first_name, last_name].join(' ').trim(),
            balance,
            jc,
            pdist,
            avg_passengers,
          };

          const filteredObjValues = shownColumns.map((el) => mapData[el]);

          array.push(filteredObjValues);
        }
      );
    };

    if (leaguetable?.length !== 0) {
      array.push([
        `${t('leaderboards')} - ${t(
          XLSX_TRANSLATION_MAPPING[leaderboardTypeParam] || 'total'
        )}`,
      ]);

      array.push(shownColumns.map((el) => (translationKeys as any)?.[el]));

      // DATA LEADERBOARD
      mapLeaderboardData(leaguetable);
    }

    if (
      leaguetableTest &&
      leaguetableTest?.length !== 0 &&
      leaderboardTypeParam !== 'point'
    ) {
      array.push([
        `${t('leaderboards')} - ${t(
          XLSX_TRANSLATION_MAPPING[leaderboardTypeParam] || 'total'
        )} ${t('reports-chart_legend_test_users')}`,
      ]);

      array.push(shownColumns.map((el) => (translationKeys as any)?.[el]));

      //TEST DATA LEADERBOARD
      mapLeaderboardData(leaguetableTest);
    }

    return array;
  }, [
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    communityId,
    dateRange,
    directionsFilter,
    leaderboardType,
    leaderboardTypeParam,
    search,
    selectedDepotFilter?.data.from,
    selectedDepotFilter?.data.to,
    shownColumns,
    t,
    translationKeys,
    unit,
  ]);

  const generateXLSX = useGenerateXLSX(
    t('leaderboards'),
    () => getLeaderboardDataForXLSX(),
    columnWidths
  );

  return (
    <PageLayout className={classNames('kinto-page')}>
      <Styled.Leaderboards>
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__heading"
        >
          {t('leaderboards')}
        </Heading>

        <Tabs
          className="leaderboards__tabs"
          tabs={tabsOptions}
          onChange={(value) => {
            setLeaderboardType(value as LeaderboardType);
            setLeaderboardTypeParam(value === 'points' ? 'point' : 'all');
            setJourneyLeaderboardDateFilter('total');
            setDirectionsFilter([]);
            setCount(DEFAULT_COUNT_PER_PAGE);
          }}
          value={leaderboardType}
        />

        <div className="leaderboards__filters">
          {leaderboardType !== 'points' && (
            <InputField
              value={search}
              onChange={onSearchChange}
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder={t('selgroup_search')}
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label={t('delete_input')}
                    icon={<Icon name="xmark" />}
                    onClick={() => {
                      onSearchChange('');
                    }}
                  />
                ),
              })}
            />
          )}
          <Select
            value={leaderboardTypeParam}
            options={finalOptions}
            onChange={(opt) => {
              if (leaderboardType === 'points') {
                setSelectedDepotFilter(
                  depotFilters.find((el) => el.id === +opt.value)
                );
              }
              setLeaderboardTypeParam(opt.value as LeaderboardGetParamType);
              setCurrentPage(1);
              setCount(DEFAULT_COUNT_PER_PAGE);
            }}
          />
          {leaderboardType !== 'points' && (
            <>
              <FiltersButton
                activeFiltersCount={directionsFilter.length}
                onClick={() => setDirectionsFiltesModalOpen(true)}
                buttonRef={filterBtnRef}
              />
            </>
          )}
          <Button
            onClick={generateXLSX}
            disabled={data.length === 0}
            variant="outline"
          >
            {`${t('export_to')} XLSX`}
          </Button>
        </div>

        {leaderboardType !== 'points' && (
          <DatePicker
            value={dateRange}
            startingRangeDate={reportsConfig.range_start}
            initialRadioDateRange={'30days'}
            dateFormat={dateFormat}
            openPosition="bottom-left"
            className="leaderboards__date-picker"
            formButtonsLabels={{
              submitLabel: t('apply'),
              cancelLabel: t('cancel'),
            }}
            buttonsLabels={{
              customDate: t('custom_date'),
              last30Days: t('last_value_days', { value: 30 }),
              last7Days: t('last_value_days', { value: 7 }),
              last3Days: t('last_value_days', { value: 3 }),
            }}
            onChange={setDateRange}
          />
        )}

        <Table<LeaderboardData>
          tableData={data}
          tableId="leaderboard-table"
          noDataPlaceholder={
            <NoData className="kinto-no-data">
              {t('search_no_results_found')}
            </NoData>
          }
          translationKeys={translationKeys}
          excludeSortColumns={[
            'id',
            'name',
            'jc',
            'position',
            'pdist',
            'avg_passengers',
          ]}
          initialSort={activeColumnSort as SortOption<LeaderboardData>}
          onColumnSortClicked={handleOnColumnSort}
          excludeColumns={
            [
              'last_name',
              'pc',
              'screen_name',
              'distance_saved',
              'tspic',
              'upc',
              'user_id',
            ].concat(
              leaderboardTypeParam === 'driving' ? [] : ['avg_passengers'],
              leaderboardTypeParam !== 'all' ? [] : ['pdist'],
              leaderboardType === 'points' || !!Number(leaderboardType)
                ? ['pdist', 'jc']
                : ['balance']
            ) as (keyof LeaderboardData)[]
          }
          perPageLabel={t('per_page')}
          chevronBtnAriaLabel={t('per_page')}
          perPagePlaceholder={t('per_page')}
          pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
          pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
          paginationData={{
            limit: count,
            totalPages,
            totalCount,
            currentPage,
          }}
          goToPageInputProps={{ placeholder: t('global-go_to_page') }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCount(+data.value as keyof typeof PAGE_LIMIT);
          }}
        />

        {directionsFiltesModalOpen && (
          <FiltersModal
            onClose={() => setDirectionsFiltesModalOpen(false)}
            filters={filters}
            onSubmit={async (val: any) => {
              setDirectionsFilter(val.directions || []);
            }}
            triggerRef={filterBtnRef.current!}
            initialData={{ directions: directionsFilter }}
          />
        )}
      </Styled.Leaderboards>
    </PageLayout>
  );
};

export default Leaderboards;
