import { Button } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { CarParkProps } from '../../../Settings/CarParkSettings/CarParkSettings.component';

import * as Styled from './CarParkElement.styles';

type CarParkElementProps = {
  carpark: CarParkProps;
  onRaiseBarrier: () => void;
};

const CarParkElement = (props: CarParkElementProps) => {
  const { carpark, onRaiseBarrier } = props;

  const { t } = useTranslation();

  return (
    <Styled.CarParkElement>
      <div className="carpark__title">{carpark.name}</div>
      <Button onClick={onRaiseBarrier}>
        {t('parking-button_raise_a_barrier')}
      </Button>
    </Styled.CarParkElement>
  );
};

export default CarParkElement;
