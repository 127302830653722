import { useMemo, useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from '@faxi/web-component-library';

import { ReportsContext, UserContext } from 'store';
import PlaceholderChart from '../PlaceholderChart';
import { useChartDate } from '../hooks';
import { ChartData, ChartAffectedByFiltersType } from 'models';

const PassengerDistancesChart: FC<ChartAffectedByFiltersType> = (
  props: ChartAffectedByFiltersType
) => {
  const { activeDirectionFilters } = props;

  const { includeTestUsers } = useContext(UserContext);
  const { kpiData, rangeStatsReportError } = useContext(ReportsContext);

  const { t } = useTranslation();

  const seriesChartData = useMemo(() => {
    if (
      !kpiData ||
      (includeTestUsers && !kpiData.passengerDistances.testChartData)
    ) {
      return [];
    }
    return [
      {
        name: `${t('total_for_chosen_directions')} (${activeDirectionFilters})`,
        data: kpiData.passengerDistances.chartData,
      },
      ...(includeTestUsers
        ? [
            {
              name: `${t(
                'total_for_chosen_directions_test'
              )} (${activeDirectionFilters})`,
              data: kpiData.passengerDistances.testChartData as ChartData,
            },
          ]
        : []),
    ];
  }, [activeDirectionFilters, includeTestUsers, kpiData, t]);

  const dateObject = useChartDate(seriesChartData);

  return rangeStatsReportError ? (
    <PlaceholderChart />
  ) : (
    <Chart
      id="passenger-distances-chart"
      series={seriesChartData}
      yAxisLabel={t('passenger_distances')}
      ariaLabel={t('accessibility_chart_label', {
        name: t('passenger_distances'),
        endDate: dateObject?.end,
        startDate: dateObject?.start,
      })}
      {...props}
    />
  );
};

export default PassengerDistancesChart;
