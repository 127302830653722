import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CarParkElement = styled.div`
  ${flex('column')}
  border: 1px solid var(--SHADE_1_6);
  border-radius: ${theme.sizes.SIZE_6};
  padding: ${theme.sizes.SIZE_16};

  .carpark {
    &__title {
      font-size: ${theme.fontSizes.FONT_16};
      line-height: ${theme.fontSizes.FONT_18};
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin-bottom: ${theme.sizes.SIZE_24};
    }
  }
`;
