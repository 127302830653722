import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { FieldProps } from '@faxi/web-form';
import { TagsInput, Tag } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

type TagsInputComponent = typeof TagsInput;
type ExtractPropsTypes<Type> = Type extends FC<infer P> ? P : never;
type TagsInputProps = ExtractPropsTypes<TagsInputComponent>;

type ValueOnChange = (event: Tag[]) => void;

type TagsFieldProps = FieldProps<Tag[], ValueOnChange> & TagsInputProps;

const TagsField: FC<PropsWithChildren<TagsFieldProps>> = (props) => {
  const {
    value,
    error,
    dirty,
    children,
    touched,
    onChange,
    onBlur,
    id,
    ...rest
  } = props;

  const tagsInputRef = useRef<{ setTags: (tags: Tag[]) => void }>(null);

  const { t } = useTranslation();

  useEffect(() => {
    tagsInputRef?.current?.setTags(value || []);
  }, [value, tagsInputRef]);

  return (
    <TagsInput
      ref={tagsInputRef}
      id={id}
      {...rest}
      initialTags={value}
      onChange={onChange}
      removeTagAriaLabel={(name) => t('accessibility-button_delete', { name })}
    />
  );
};

export default TagsField;
