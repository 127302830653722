import { ReactNode } from 'react';
import { Step } from '@faxi/web-form';
import { Navigate } from 'react-router-dom';

import { appUri } from '../../config';
import { ExtendRouteObject } from '../../router/routes';

import {
  Initial,
  AlreadyCompleted,
  SurveyFinished,
  YourEmissions,
  SurveyForm,
  SurveyNotStarted,
} from './pages';
import NotFound from '../NotFound';

//survey form steps
import {
  CommutingPattern,
  HomeAddress,
  DestinationAddress,
  ModeOfTransport,
  VehicleInformation,
} from './pages/Form';
import { SurveyForm as SurveyFormType } from '../../models/Survey';

const SURVEY_FORM_NUM_STEPS = 5;

export type RouteStep = Omit<Step, 'component'> & {
  element?: ReactNode;
  title: string;
  question: string;
};

export const surveyFormSteps = [
  {
    path: appUri.SURVEY_START_COMMUTING_PATTERN,
    handle: 'Survey commuting pattern',
    title: 'global-commuting_pattern',
    question: 'sustainability-title_working_pattern',
    element: <CommutingPattern />,
  },
  {
    path: appUri.SURVEY_START_HOME_ADDRESS,
    handle: 'Survey home address',
    title: 'settings_home_title',
    question: 'Can you confirm your home address?',
    element: <HomeAddress />,
  },
  {
    path: appUri.SURVEY_START_DESTINATION_ADDRESS,
    handle: 'Survey destination address',
    title: 'global-workplace_address',
    question: 'sustainability-title_workplace_address_page',
    element: <DestinationAddress />,
  },
  {
    path: appUri.SURVEY_START_MODE_OF_TRANSPORT,
    handle: 'Survey mode of transport',
    title: 'mode_of_transport',
    question: 'sustainability-title_commuting_pattern_page',
    element: <ModeOfTransport />,
    fallbackStep: SURVEY_FORM_NUM_STEPS,
    validate: (data: SurveyFormType) =>
      data?.['commuting-pattern'] !== 'remote',
  },
  {
    path: appUri.SURVEY_START_VEHICLE_INFORMATION,
    handle: 'Survey vehicle information',
    title: 'vehicle_information',
    question: 'sustainability-title_your_vehicle_page',
    element: <VehicleInformation />,
    fallbackStep: SURVEY_FORM_NUM_STEPS,
    validate: (data: SurveyFormType) =>
      data?.['commuting-pattern'] !== 'remote' && +data.driving > 0,
  },
] as RouteStep[];

export default [
  {
    handle: '',
    path: ':surveySlug',
    children: [
      {
        handle: 'Survey Initial',
        path: '',
        element: <Initial />,
      },
      {
        handle: 'Survey start',
        path: appUri.SURVEY_START,
        element: <SurveyForm />,
        children: surveyFormSteps,
      },
      {
        handle: 'Survey already completed',
        path: appUri.SURVEY_ALREADY_COMPLETED,
        element: <AlreadyCompleted />,
      },
      {
        handle: 'Survey finished',
        path: appUri.SURVEY_FINISHED,
        element: <SurveyFinished />,
      },
      {
        handle: 'Survey not started',
        path: appUri.SURVEY_SCHEDULED,
        element: <SurveyNotStarted />,
      },
      {
        handle: 'Survey emissions',
        path: appUri.SURVEY_YOUR_EMISSIONS,
        element: <YourEmissions />,
      },
      {
        handle: 'Survey not found',
        path: appUri.NOT_FOUND,
        element: <NotFound />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
  {
    index: true,
    element: <Navigate to={`/${appUri.LOGIN}`} />,
  },
] as ExtendRouteObject[];
