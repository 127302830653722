import { useMemo } from 'react';
import { useQueryParams } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { areArraysEqual, JOURNEY_DIRECTIONS } from 'utils';

const allDirections = Object.keys(JOURNEY_DIRECTIONS);

export default function useActiveDirectionsFilters() {
  const {
    params: { directions },
  } = useQueryParams<{
    directions: string;
  }>();

  const { t } = useTranslation();

  const activeDirectionFilters = useMemo(() => {
    // this is case if all filters are selected or there is not selected filters
    // in that case we show just one word 'all'
    if (!directions || areArraysEqual(allDirections, directions?.split(','))) {
      return t('joinGroup_search_filter_all').toLowerCase();
    }

    return directions
      ?.split(',')
      .filter(Boolean)
      .map((d) =>
        t(
          JOURNEY_DIRECTIONS[d as keyof typeof JOURNEY_DIRECTIONS]
        ).toLowerCase()
      )
      .join(', ');
  }, [directions, t]);

  return activeDirectionFilters;
}
