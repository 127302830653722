import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';
import { authBus } from 'modules';

dayjs.extend(utc);

const noop = () => {};

function getStringError(error: AxiosError<any>): string {
  if (!error?.response?.data) return "Couldn't parse error";

  let {
    response: {
      data: { message },
    },
  } = error;

  if (!message) return "Couldn't parse error";

  message =
    typeof message === 'string'
      ? message
      : typeof message.errors[0].message === 'string'
      ? message.errors[0].message
      : 'Invalid data';
  return message;
}

function snackBarSuccessMessage(message: string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'success',
      actionButtonText: i18next.t('dismiss'),
      text: message,
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

function snackBarErrorMessage(error: AxiosError | string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'error',
      actionButtonText: i18next.t('dismiss'),
      text: typeof error === 'string' ? error : getStringError(error),
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

function isNonEmptyString(value: any) {
  return value.trim().length > 0;
}

function scrollToTop(el?: HTMLElement) {
  const options = {
    top: 0,
    behavior: 'smooth',
  } as ScrollOptions;

  if (el) el.scrollTo(options);
  else window.scrollTo(options);
}

const capitalizeFirstLetter = (sentence: string) =>
  `${sentence.charAt(0).toUpperCase()}${sentence.slice(1).toLowerCase()}`;

const getFormData = (obj: Record<string, any>) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    const finalValue = Array.isArray(value) ? JSON.stringify(value) : value;
    formData.append(key, finalValue as any);
  });

  return formData;
};

const pluralKey = (key: string, count: number) =>
  key + (count === 1 ? '' : '_plural');

const sliceStringLikeEllipsis = (
  content: string,
  numberOfCharacters: number
): string => {
  if (content.length <= numberOfCharacters || numberOfCharacters <= 0)
    return content;

  return `${content.slice(0, numberOfCharacters)}...`;
};

const convertKgToTonsIfNeeded = (amount: number) => {
  if (amount < 1000) return amount;

  return amount / 1000;
};

/*
  Function for generate url as string, based on queryParams object
**/
const createUrl = (
  baseUrl: string,
  queryParams: Record<string, string | number | undefined>
): string => {
  const url = new URL(baseUrl);

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value) {
      url.searchParams.append(key, String(value));
    }
  });

  return url.toString();
};

/*
  Function used to delay the call of the callback function
  params: 
      callback - function we want to postpone
      ms - milliseconds in which function will be postponed
**/
function delayWithExecution(callback: () => void, ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, ms);
  });
}

/*
  Function used to check if two arrays are equal,
  order of elements doesnt metter
**/
function areArraysEqual(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) return false;

  return JSON.stringify([...arr1].sort()) === JSON.stringify([...arr2].sort());
}

export {
  noop,
  pluralKey,
  scrollToTop,
  getStringError,
  snackBarErrorMessage,
  snackBarSuccessMessage,
  isNonEmptyString,
  getFormData,
  capitalizeFirstLetter,
  sliceStringLikeEllipsis,
  convertKgToTonsIfNeeded,
  createUrl,
  delayWithExecution,
  areArraysEqual,
};
