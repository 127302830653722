import React, { Fragment, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Input,
  useUtilities,
  Icon,
} from '@faxi/web-component-library';
import { debounce } from 'lodash';
import { TimeIcon } from 'icons';

import * as ModalsStyled from '../../../../../components/_modals/EmailListModal/EmailListModal.styles';

type EmailsModalProps = {
  emailList: string[];
  onClose: () => void;
  onRemoveEmail: (email: string) => void;
};

const EmailsModal = (props: EmailsModalProps) => {
  const { emailList, onClose, onRemoveEmail } = props;

  const { t } = useTranslation();

  const { showOverlay, hideOverlay } = useUtilities();

  const [search, setSearch] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const debounceSearch = useMemo(
    () =>
      debounce((value) => {
        setDebouncedSearchTerm(value);
        hideOverlay('.email-list-modal__emails');
      }, 300),
    [hideOverlay]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      showOverlay('.email-list-modal__emails');
      setSearch(value);
      debounceSearch(value);
    },
    [debounceSearch, showOverlay]
  );

  // Filter the items based on the debounced search term
  const filteredItems = useMemo(
    () =>
      emailList.filter((item) =>
        item.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      ),
    [debouncedSearchTerm, emailList]
  );

  const handleRemoveEmail = useCallback(
    (email: string) => {
      onRemoveEmail(email);
    },
    [onRemoveEmail]
  );

  return (
    <ModalsStyled.EmailListModal
      className="email-list-modal"
      onClose={() => onClose()}
      title={t('preapproved_emails')}
    >
      <div className={classNames('email-list-modal__body')}>
        <Input
          placeholder={t('preapproved_settings-placeholder_search_an_email')}
          prefixIcon={<Icon name="magnifying-glass" />}
          value={search}
          onChange={handleInputChange}
          className="email-list-modal__search"
        />
        <div className="email-list-modal__emails">
          {filteredItems.length > 0 ? (
            filteredItems?.map((email) => (
              <Fragment key={email}>
                <div key={email} className="email-list-modal__body__row">
                  <div
                    className={classNames('email-list-modal__body__row__email')}
                  >
                    {email}
                  </div>
                  <Button
                    aria-label={t('accessibility-button_delete', {
                      name: email,
                    })}
                    className="email-list-modal__body__row__remove"
                    variant="ghost"
                    onClick={() => handleRemoveEmail(email)}
                  >
                    <TimeIcon />
                  </Button>
                </div>
                <Divider />
              </Fragment>
            ))
          ) : (
            <div className="email-list-modal__emails__empty-state">
              {t('selgroup_no_results_1')}
            </div>
          )}
        </div>
      </div>
    </ModalsStyled.EmailListModal>
  );
};

export default React.memo(EmailsModal);
