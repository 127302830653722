import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from '@faxi/web-component-library';

import * as Styles from './NotFound.styles';

const NotFound: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Styles.NotFound className="kinto-not-found">
      <Image
        alt=""
        className="kinto-not-found__image"
        src="/assets/images/not-found.webp"
      />

      <p className="kinto-not-found__title">
        {t('page_not_found-empty_state_title')}
      </p>

      <p className="kinto-not-found__description">
        {t('page_not_found-empty_substate_title')}
      </p>

      <Button variant="outline" onClick={() => navigate('/')}>
        {t('go_to-button', { page: t('mHome') })}
      </Button>
    </Styles.NotFound>
  );
};

export default NotFound;
