import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const SpinnerWithLabel = styled.div`
  ${flex('column', undefined, 'center')}

  .spinner {
    margin-bottom: ${theme.sizes.SIZE_16};
  }
`;
