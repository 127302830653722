import { Fragment, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { SettingsForm } from '../../../../../components/_layouts/Containers';
import { FormField, validationRegexes } from '@faxi/web-form';
import {
  Button,
  Divider,
  getColor,
  Icon,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { FileInput } from '../../../../../components';
import { useDropzone } from 'react-dropzone';
import { uniqBy } from 'lodash';

import * as ModalsStyled from '../../../../../components/_modals/EmailListModal/EmailListModal.styles';

type UploadEmailsProps = {
  onClose: () => void;
  onSubmit: (emailList: string[]) => void;
};

const UploadEmails = (props: UploadEmailsProps) => {
  const { onClose, onSubmit } = props;

  const [emailList, setEmailList] =
    useState<{ email: string; valid: boolean }[]>();

  const { showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
    noClick: true,
    noKeyboard: true,
    // COMMENT: limit is 200kB
    maxSize: 200000,
    onDropAccepted: (files) => {
      const filteredFiles: File[] = [];

      files.forEach(async (file) => {
        const reader = new FileReader();

        reader.readAsText(file);

        const loadFilePromise = new Promise(
          (resolve, reject) =>
            (reader.onload = async (e) => {
              if (!e || !e.target || !e.target.result) {
                reject();
              }

              const emails = uniqBy(
                e!
                  .target!.result?.toString()
                  .split(/\r?\n/)
                  .filter((el) => el !== '')
                  .map((val) => ({
                    email: val,
                    valid: validationRegexes.workEmail.test(val),
                  }))
                  .sort((a, b) => (a.email > b.email ? 1 : -1)),
                ({ email }) => email
              );

              const validEmails = emails.filter((el) => el.valid);
              const invalidEmails = emails.filter((el) => !el.valid);

              setEmailList(invalidEmails?.concat(validEmails || []));

              resolve(file);

              filteredFiles.push(file);
            })
        );

        await loadFilePromise;
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        const error = rejection.errors[0].code;

        switch (error) {
          case 'file-invalid-type': {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: t('community_settings-error_csv_not_supported'),
              variant: 'error',
            });
            return;
          }
          case 'file-too-large': {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: t('file_larger_than', { size: '200kB' }),
              variant: 'error',
            });
            return;
          }
          default: {
            showSnackBar({
              actionButtonText: t('dismiss'),
              text: t('dw_msg_error_general'),
              variant: 'error',
            });
          }
        }
      });
    },
  });

  const removeEmailFromList = useCallback((email: string) => {
    setEmailList((old) => old?.filter((el) => el.email !== email));
  }, []);

  const submitPreapprovedEmails = useCallback(() => {
    onSubmit(emailList?.map((email) => email.email) || []);
    onClose();
  }, [emailList, onClose, onSubmit]);

  const hasInvalidEmails = useMemo(
    () => emailList?.some((el) => !el.valid),
    [emailList]
  );

  return (
    <SettingsForm className="kinto-settings-form" visible>
      <div
        color={getColor('--PRIMARY_1_1')}
        className="kinto-dropzone-form__title"
      >
        {t('upload_from_device')}
      </div>
      <div className="kinto-dropzone-form__content" {...getRootProps()}>
        <Icon
          name="image"
          color={getColor('--SHADE_1_5')}
          className="kinto-dropzone-form__content__img"
        />
        <div
          className="kinto-dropzone-form__content__text"
          color={getColor('--SHADE_1_2')}
        >
          {t('community_settings-drop_your_csv_here')}
        </div>
        <Button
          onClick={() => inputRef.current?.click()}
          icon={<Icon name="chevron-right" />}
          variant="ghost"
          iconPosition="right"
        >
          {t('select_from_file')}
        </Button>
        <FormField
          inputProps={getInputProps()}
          component={FileInput}
          name="csv"
        />
      </div>
      <Button
        variant="ghost"
        icon={<Icon name="chevron-left" />}
        onClick={onClose}
      >
        {t('Back')}
      </Button>

      {emailList && (
        <ModalsStyled.EmailListModal
          className="email-list-modal"
          onClose={() => onClose()}
          title={t('preapproved_emails')}
          footer={
            <Button
              onClick={submitPreapprovedEmails}
              disabled={hasInvalidEmails}
            >
              {t('add')}
            </Button>
          }
        >
          <div
            className={classNames(
              'email-list-modal__body',
              'email-list-modal__body--padded-right'
            )}
          >
            {emailList?.map((emailObj, index) => (
              <Fragment key={`email_${index}`}>
                <div className="email-list-modal__body__row">
                  <div
                    className={classNames(
                      'email-list-modal__body__row__email',
                      {
                        'email-list-modal__body__row__email--invalid':
                          !emailObj.valid,
                      }
                    )}
                  >
                    {emailObj.email}
                  </div>
                  <Button
                    icon={<Icon name="xmark" />}
                    aria-label={t('accessibility-button_delete', {
                      name: emailObj.email,
                    })}
                    className="email-list-modal__body__row__remove"
                    variant="ghost"
                    onClick={() => removeEmailFromList(emailObj.email)}
                  />
                </div>
                {index < emailList.length - 1 && <Divider />}
              </Fragment>
            ))}
          </div>
        </ModalsStyled.EmailListModal>
      )}
    </SettingsForm>
  );
};

export default UploadEmails;
