import { useMemo, useContext, useState, FC, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  useCallbackRef,
  Breadcrumbs,
  useFormButtons,
  TabView,
  useUtilities,
} from '@faxi/web-component-library';
import {
  FormRef,
  FormField,
  validators,
  Form,
  FormProviderProps,
} from '@faxi/web-form';

import { CarParkContext, CarSpaceContext } from 'store';
import { FormActions, Icon, InputField } from 'components';
import { CarParkProps, CarSpaceProps } from '../CarParkSettings.component';
import specific from 'validation/validators/specific';
import regex from 'validation/regex';
import { SettingsForm } from 'components/_layouts/Containers';

type CarSpaceFormProps = {
  carSpaces: string[];
  index: number;
  onSubmit: (data: any) => void;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
} & FormProviderProps;

const CarSpaceForm = (props: CarSpaceFormProps) => {
  const {
    carSpaces,
    initialData,
    index,
    onSubmit,
    onClickCancel,
    onClickDelete,
  } = props;

  const { showSnackBar } = useUtilities();

  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const validations = useMemo(
    () => ({
      carSpaceName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('carpark_settings-placeholder_parking_space_name'),
          })
        ),
        validators.general.regex(
          regex.carParkRegex,
          t('validation-carpark_name', {
            fieldname: t(
              'carpark_settings-placeholder_parking_space_name'
            ).toLowerCase(),
          })
        ),
        specific.carSpaceNameDuplicate(
          t('validation-unique_name', {
            fieldname: t(
              'carpark_settings-placeholder_parking_space_name'
            ).toLowerCase(),
          }),
          index,
          carSpaces
        ),
        validators.general.maxLength(
          20,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '20',
          })
        ),
      ],
    }),
    [carSpaces, index, t]
  );

  return (
    <Form
      onSubmit={async (data) => {
        onSubmit(data);
        form.updateValueField('carSpaceName', '');
        form.clearFormValidationProps();

        showSnackBar({
          text: t('success'),
          actionButtonText: t('dismiss'),
          variant: 'success',
        });
      }}
      initialData={initialData}
      ref={formRef}
    >
      <fieldset className="form__fields">
        <legend data-hidden hidden>
          {t('car_park_name')}
        </legend>
        <FormField
          id="car_space_name"
          name="carSpaceName"
          autoComplete="on"
          type="text"
          autoFocus
          component={InputField}
          placeholder={t('carpark_settings-placeholder_parking_space_name')}
          validate={validations.carSpaceName}
          required
          requiredLabel={t('global-input_field_required_label')}
        />
      </fieldset>

      <FormActions className="form__actions">
        <FormButtons.Submit
          id="submit_car_park"
          disabled={!form?.isFormChanged() || !form?.syncFormValid}
        />
        <FormButtons.Cancel
          onClick={onClickCancel}
          id={`cancel_custom_info_new_input_${initialData?.id}`}
        />
        {onClickDelete && (
          <Button
            id="remove_parking_space"
            type="button"
            icon={<Icon name="trash-can" />}
            variant="delete-ghost"
            iconPosition="right"
            onClick={onClickDelete}
          >
            {t('Discovery_map_delete')}
          </Button>
        )}
      </FormActions>
    </Form>
  );
};

export const CarSpace: FC<{ carSpace: CarSpaceProps; index: number }> = (
  props
): JSX.Element => {
  const {
    carSpace: { id, name },
    index,
  } = props;

  const { carParkSpaces, updateCarSpace, removeCarSpace } =
    useContext(CarSpaceContext);

  const { t } = useTranslation();
  const { prompts } = useUtilities();

  const currentCarParkSpace = useMemo(
    () => carParkSpaces.find((carSpace: CarSpaceProps) => carSpace.id === id),
    [carParkSpaces, id]
  );

  const initialData = useMemo(
    () => ({ carSpaceName: currentCarParkSpace?.name, id }),
    [currentCarParkSpace, id]
  );

  const [edit, setEdit] = useState(false);

  return (
    <div className="tab-view__row">
      {edit ? (
        <CarSpaceForm
          index={index}
          onSubmit={(data: { carSpaceName: string }) => {
            updateCarSpace(data.carSpaceName, id);
            setEdit(false);
          }}
          carSpaces={carParkSpaces.map(({ name }) => name)}
          initialData={initialData}
          onClickCancel={() => setEdit(false)}
          onClickDelete={async (e) => {
            if (
              await prompts.delete({
                title: t('deleting_parking_space'),
                confirmButtonText: t('Discovery_map_delete'),
                cancelButtonText: t('cancel'),
                triggerRef: e.target as HTMLButtonElement,
              })
            ) {
              const parkNameInput = document.querySelector(
                `#add_parking_space_btn`
              );
              if (parkNameInput) {
                (parkNameInput as HTMLElement).focus();
              }

              removeCarSpace(id);
              setEdit(false);
            }
          }}
        />
      ) : (
        <Fragment>
          <div className="tab-view__row__title">{`[ID] ${t(
            'carpark_settings-placeholder_parking_space_name'
          )}`}</div>
          <div className="tab-view__row__content">
            <div>{`[${id}] ${name}`}</div>
            <Button
              icon={<Icon name="pen" />}
              iconPosition="right"
              variant="ghost"
              id={`edit_car_park_${id}`}
              onClick={() => setEdit(true)}
            >
              {t('as_edit')}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export const CarParkInfo: FC = () => {
  const { carParks, updateCarParkName } = useContext(CarParkContext);

  const { carParkSpaces, addNewCarSpace } = useContext(CarSpaceContext);

  const { t } = useTranslation();

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
  });

  const [addingParkingSpace, setAddingParkingSpace] = useState(false);

  const { carParkId } = useParams<{ carParkId: string }>();

  const navigate = useNavigate();

  const [carParkForm, carParkFormRef] = useCallbackRef<FormRef>();

  const carPark = useMemo(
    () => carParks?.find((carPark: CarParkProps) => carPark.id === carParkId),
    [carParkId, carParks]
  );

  const initialData = useMemo(
    () => ({
      name: carPark?.name,
    }),
    [carPark]
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('car_park_name') })
        ),
        validators.general.regex(
          regex.carParkRegex,
          t('validation-carpark_name', {
            fieldname: t('car_park_name').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('car_park_name').toLowerCase(),
            number: '50',
          })
        ),
      ],
    }),
    [t]
  );

  return (
    <SettingsForm>
      <Breadcrumbs
        crumbs={[
          {
            id: 'car_park_settings',
            text: t('car_park_settings'),
            href: '..',
          },
          {
            id: 'car_park_name',
            text: `${carPark?.name || ''}`,
            href: '..',
          },
        ]}
      />

      <Button
        id="back_to_car_park_list"
        icon={<Icon name="chevron-left" />}
        variant="ghost"
        iconPosition="left"
        className="kinto-car-park__back-to-car-park"
        onClick={() => navigate('..')}
      >
        {t('back_to_car_park_list')}
      </Button>

      <Form
        onSubmit={async (data: FormData) => {
          updateCarParkName(data, carParkId!);
        }}
        initialData={initialData}
        ref={carParkFormRef}
      >
        <div className="form__fields">
          <FormField
            id="car_park_name"
            name="name"
            type="text"
            autoComplete="on"
            component={InputField}
            placeholder={t('car_park_name')}
            validate={validations.name}
            required
            requiredLabel={t('global-input_field_required_label')}
          />
        </div>

        <FormActions className="form__actions">
          <FormButtons.Submit
            id={`submit_car_park_${carParkId}`}
            disabled={
              !carParkForm?.isFormChanged() || !carParkForm?.syncFormValid
            }
          />
        </FormActions>
      </Form>

      <div className="kinto-car-park__list-title">
        {t('list_of_parking_spaces')}
      </div>

      <TabView hasBorders>
        {carParkSpaces.map((carSpace, index) => (
          <CarSpace
            index={index}
            carSpace={carSpace}
            key={`${carSpace.id}_${carSpace.name}`}
          />
        ))}

        {addingParkingSpace && (
          <div className="tab-view__row">
            <CarSpaceForm
              index={-1}
              onSubmit={(data: { carSpaceName: string }) => {
                addNewCarSpace(data.carSpaceName);
                setAddingParkingSpace(false);
              }}
              carSpaces={carParkSpaces.map(({ name }) => name)}
              onClickCancel={() => setAddingParkingSpace(false)}
            />
          </div>
        )}
      </TabView>

      <FormActions>
        <Button
          id="add_parking_space_btn"
          icon={<Icon name="plus" />}
          iconPosition="left"
          className="kinto-car-park__add-parking-space-button"
          onClick={() => setAddingParkingSpace(true)}
        >
          {t('add_parking_space')}
        </Button>
      </FormActions>
    </SettingsForm>
  );
};
