import { useState, useContext, useCallback, FC, useRef, useMemo } from 'react';
import {
  Button,
  Heading,
  InfiniteScroll,
  Input,
  getColor,
  useCallbackRef,
  useGlowScroll,
  useUtilities,
  useModalUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';

import config from 'config';
import { UserContext } from 'store';
import { apiEvents } from 'modules';
import { EventModal, Icon, SettingsTabEmptyScreen } from 'components';
import { useInfinitePagination } from 'hooks';
import { Event } from 'models';
import EventCard from './components/EventCard';
import { useTranslation } from 'react-i18next';
import { dateString } from 'utils';
import { PageLayout } from 'components/_layouts';

import { NoData } from 'Global.styles';
import classNames from 'classnames';

const EVENTS_PER_PAGE = 15;

const EventsTab: FC = (): JSX.Element => {
  const { communityId } = useContext(UserContext);

  const { t } = useTranslation();

  const { prompts, showSnackBar } = useUtilities();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();
  const modalTriggerBtn = triggerRef.current as HTMLButtonElement;

  const [activeEventId, setActiveEventId] = useState<number>();
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);

  const [infiniteScroll, infiniteScrollRef] = useCallbackRef<HTMLDivElement>();

  const createModalBtnRef = useRef<HTMLButtonElement>(null);
  const glowScrollContainerRef = useRef<HTMLDivElement>(null);

  useGlowScroll(
    glowScrollContainerRef.current,
    infiniteScroll,
    1,
    0,
    'gray',
    'vertical'
  );

  const {
    items,
    loading,
    search,
    reset,
    onSearchChange,
    handleContainerScroll,
    setLoading,
  } = useInfinitePagination<Event>({
    perPage: EVENTS_PER_PAGE,
    condition: !!communityId,
    resetDeps: [communityId],
    spinnerParent: '.kinto-page',
    apiRequest: async (page: string, search: string) => {
      const { appointments, count } = await apiEvents.getAll({
        communityId: communityId!,
        offset: +page,
        search,
        count: EVENTS_PER_PAGE,
      });

      return {
        data: appointments,
        total: count,
      };
    },
    onItemsLoad: (offset, items) => {
      setShowEmptyScreen(items.length === 0 && offset === 0);
    },
  });

  const selectedEvent = useMemo(
    () => (items as Event[]).find((event) => event.id === activeEventId),
    [activeEventId, items]
  );

  const initialData = useMemo(
    () => ({
      name: selectedEvent?.title,
      description: selectedEvent?.description,
      address: {
        formatted_address: selectedEvent?.address,
        lat: selectedEvent?.lat,
        lng: selectedEvent?.lng,
        city: selectedEvent?.city,
        country: selectedEvent?.country,
      },
      start_date: dayjs(
        dateString({
          date: selectedEvent?.start_date,
          time: selectedEvent?.start_time,
          format: config.dateFormat,
        }),
        config.dateFormat
      ),
      ...(selectedEvent?.end_date && {
        end_date: dayjs(
          dateString({
            date: selectedEvent?.end_date,
            time: selectedEvent?.end_time,
            format: config.dateFormat,
          }),
          config.dateFormat
        ),
      }),

      start_time: dateString({
        date: selectedEvent?.start_date,
        time: selectedEvent?.start_time,
        format: config.timeFormat,
      }),
      end_time: selectedEvent?.end_time
        ? dateString({
            date: selectedEvent?.start_date,
            time: selectedEvent?.end_time,
            format: config.timeFormat,
          })
        : '',
    }),
    [selectedEvent]
  );

  const deleteEvent = useCallback(
    async ({ id, title }: Event, element: HTMLElement) => {
      if (
        await prompts.delete({
          title: t('events-dialog_body_delete_event', { title }),
          confirmButtonText: t('Discovery_map_delete'),
          cancelButtonText: t('cancel'),
          triggerRef: element,
        })
      ) {
        try {
          setLoading(true);

          await apiEvents.remove(id);

          reset();

          showSnackBar({
            text: t('events-toast_event_successfully_deleted', { title }),
            variant: 'success',
            actionButtonText: t('dismiss'),
          });
        } catch (e) {
          console.error(e);
        } finally {
          closeModal();
          setLoading(false);
        }
      }
    },
    [prompts, t, setLoading, reset, showSnackBar, closeModal]
  );

  const handleOpenEventModal = useCallback(() => {
    setActiveEventId(undefined);
    openModal();
  }, [openModal]);

  return (
    <PageLayout className={classNames('kinto-page', 'events')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('events-title_events')}
      </Heading>

      {showEmptyScreen && !loading && !search && (
        <SettingsTabEmptyScreen
          iconName="calendar-day"
          title={t('events-empty_state_title_let_people_organise')}
          contentText={t('events-subtitle_create_event')}
          buttonLabel={t('events-button_create_event')}
          buttonCallback={handleOpenEventModal}
        />
      )}

      {(items.length > 0 || (search !== undefined && items.length >= 0)) && (
        <Input
          className="kinto-events-tab__search"
          aria-label={t('selgroup_search')}
          placeholder={t('selgroup_search')}
          prefixIcon={<Icon name="magnifying-glass" />}
          value={search}
          onChange={onSearchChange}
          {...(search && {
            suffixIcon: (
              <Button
                variant="ghost"
                icon={<Icon name="xmark" />}
                aria-label={t('delete_input')}
                onClick={() => {
                  onSearchChange('');
                }}
              />
            ),
          })}
        />
      )}

      {items.length > 0 && (
        <Button
          ref={createModalBtnRef}
          className="kinto-events-tab__button"
          variant="outline"
          onClick={handleOpenEventModal}
          icon={<Icon name="plus" />}
          iconPosition="right"
        >
          {t('events-button_create_event')}
        </Button>
      )}

      {search && items.length === 0 && (
        <NoData className="kinto-no-data">
          {t('search_no_results_found')}
        </NoData>
      )}

      <InfiniteScroll
        loading={loading}
        readyForScrolling={items.length > 0}
        localScroll={false}
        onScroll={handleContainerScroll}
        ref={infiniteScrollRef}
      >
        <div className="kinto-events-tab__events__infinite-scroll">
          {items.map((i) => (
            <EventCard
              event={i}
              key={i.id}
              dateFormat={'D MMM YYYY'}
              onClick={(event) => {
                setActiveEventId(i.id);
                openModal(event);
              }}
            />
          ))}
        </div>
      </InfiniteScroll>

      {open && (
        <EventModal
          loading={loading}
          eventId={selectedEvent?.id}
          triggerRef={modalTriggerBtn}
          onClose={() => {
            closeModal();
            setActiveEventId(undefined);
          }}
          initialData={activeEventId ? initialData : undefined}
          onEventSubmitted={() => {
            setShowEmptyScreen(false);
            reset();
          }}
          onClickDeleteEvent={(el) =>
            selectedEvent && deleteEvent(selectedEvent, el)
          }
        />
      )}
    </PageLayout>
  );
};

export default EventsTab;
