import classNames from 'classnames';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Accept, DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';

import { getPreviewImageURL } from '../../ImageDropzone.component';
import ImageCrop from '../ImageCrop';
import ImagePreview from '../ImagePreview';
import useLatestState from 'hooks/useLatestValue';
import { DropzoneFileType } from '../../hooks/useDropzoneField';

import * as Styled from './ImageCropModal.styles';

type ImageCropModalProps = {
  className?: string;
  alt?: string;
  accept?: Accept;
  file: DropzoneFileType;
  circularCrop?: boolean;
  dropzoneProps: DropzoneRootProps;
  dropzoneInputProps: DropzoneInputProps;
  onSave?: (file: File) => void;
  onDelete?: (file: File) => void;
  onClose?: () => void;
  onCloseCrop?: (
    shouldSaveFile: boolean,
    lastValid: DropzoneFileType | null
  ) => void;
} & ModalProps;

const ImageCropModal: React.FC<ImageCropModalProps> = (props) => {
  const {
    className,
    title,
    alt,
    accept,
    file: pFile,
    dropzoneProps,
    dropzoneInputProps,
    circularCrop,
    triggerRef,
    onClose,
    onCloseCrop,
    onSave,
    onDelete,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { prompts } = useUtilities();

  const fileIsChanged = useRef<boolean>(false);
  const shouldSaveFile = useRef<boolean>(Boolean(pFile));
  const lastValidImage = useRef<DropzoneFileType | null>(pFile);

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const [file, setFile] = useLatestState<DropzoneFileType | null>(pFile);
  const [showCrop, setShowCrop] = useState<boolean>(pFile ? false : true);

  const previewImageURL = useMemo(
    () => getPreviewImageURL(file as File),
    [file]
  );

  const closeModal = useCallback(() => {
    modal.close();
    onCloseCrop?.(shouldSaveFile.current, lastValidImage.current);
  }, [modal, onCloseCrop]);

  const handleOnSaveCrop = useCallback(
    (file: File) => {
      fileIsChanged.current = true;

      setShowCrop(false);
      setFile(file);
    },
    [setFile]
  );

  const handleOnCloseCrop = useCallback(() => {
    if (!lastValidImage.current) {
      closeModal();
      return;
    }

    setFile(lastValidImage.current);
    setShowCrop(false);
  }, [closeModal, setFile]);

  const handleOnEdit = useCallback(() => {
    lastValidImage.current = file;

    setFile(null);
    setShowCrop(true);
  }, [file, setFile]);

  const handleOnDelete = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      const proceed = await prompts.delete({
        title: t('upload_delete_alert'),
        confirmButtonText: t('Discovery_map_delete'),
        cancelButtonText: t('cancel'),
        triggerRef: event.target as HTMLButtonElement,
      });

      if (!proceed) return;

      closeModal();
      onDelete?.(file as File);
    },
    [closeModal, prompts, file, onDelete, t]
  );

  return (
    <Styled.ImageCropModal
      {...rest}
      className={classNames('kinto-image-crop-modal', className)}
      ref={modalRef}
      title={title}
      triggerRef={triggerRef}
      ariaCloseModal={t('accessibility-button_close_modal', {
        name: t('crop_image'),
      })}
      onClose={() => {
        onClose?.();
        closeModal();
      }}
    >
      {!showCrop && file && (
        <>
          <ImagePreview
            {...(circularCrop && {
              style: {
                borderRadius: '50%',
              },
            })}
            url={previewImageURL}
            alt={alt}
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
          />
          <div className="kinto-image-crop-modal__preview-actions">
            <Button
              variant="primary"
              disabled={!fileIsChanged.current}
              onClick={() => {
                shouldSaveFile.current = true;

                closeModal();
                onSave?.(file as File);
              }}
            >
              {t('CommuteDetails_saving_message')}
            </Button>
            <Button variant="ghost" onClick={closeModal}>
              {t('cancel')}
            </Button>
          </div>
        </>
      )}

      {showCrop && (
        <ImageCrop
          accept={accept}
          file={file as File}
          circularCrop={circularCrop}
          dropzoneProps={dropzoneProps}
          dropzoneInputProps={dropzoneInputProps}
          onSave={handleOnSaveCrop}
          onClose={handleOnCloseCrop}
          onDragEnd={() => {
            if (!showCrop) return;
            modal.skipClosing.current = true;

            setTimeout(() => {
              modal.skipClosing.current = false;
            });
          }}
        />
      )}
    </Styled.ImageCropModal>
  );
};

export default memo(ImageCropModal);
